import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { capitalize, map } from "lodash";

import { Grid, Typography } from "@mui/material";

import { CustomDatePickerControlled } from "~/common/components/DatePicker/DatePicker";
import { CustomInputControlled } from "~/common/components/Input/Input";
import CustomMultiSelectControlled from "~/common/components/TrackedComponents/CustomMultiSelect/CustomMultiSelectControlled";
import { useAppFlags, useCheckUserPermission, useMap } from "~/common/hooks";
import { useCustomSelectShiftsForUnit } from "~/common/hooks/useShiftsForUnit";
import { Uuid } from "~/common/types";
import { rosterUserEmploymentStatus } from "~/features/Roster/constants";
import { useAllStaffAttributesByKey } from "~/routes/hooks/useStaffAttributesByKeyForUnit";
import { useAllStaffAttributesQuery, useUnitQuery } from "~/routes/queries";

import { IEditUser } from "#/features/Roster/types";
import { useAppConfigQuery } from "#/features/User/queries";
import {
  IPreferenceRequirementRuleSet,
  useListPreferenceRequirementRuleSetsQuery,
  User,
} from "@/api";
import { CustomSelect, CustomSelectControlled, RuleSetLabel } from "@/common/components";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { warning } from "@/common/theming/colors";
import { homeUnitMsg } from "@/common/validation/messages";

const ruleSetToOption = (ruleSet: IPreferenceRequirementRuleSet) => ({
  label: ruleSet.name,
  value: ruleSet.id,
  item: ruleSet,
});

//Doesn't include Kiosk because we dont want to display that role in the field
const roleTypeKeysToNameObject = {
  [User.ERole.staff]: capitalize(User.ERole.staff),
  [User.ERole.scheduler]: capitalize(User.ERole.scheduler),
};

const defaultStaffRoleTypes = [User.ERole.staff, User.ERole.kiosk];

export const StaffDataForm = ({ userData }: { userData?: IEditUser }) => {
  const {
    control,
    formState: { errors, defaultValues },
    watch,
    setValue,
  } = useFormContext();

  const {
    preferenceRequirementsV2,
    enableUsersToChangeTheirEmail: isUserEmailChangeEnabled,
    multipleUserRoles,
  } = useAppFlags();
  const userCanCreateSchedulers = useCheckUserPermission(
    "manage",
    EUnitPermissionAreas.schedulerRoster,
  );

  const isOnOrientation = Boolean(watch("onOrientation"));
  const homeUnitId = watch("homeUnitId") as Uuid | undefined;

  const changedHomeUnitId = defaultValues && defaultValues?.homeUnitId !== homeUnitId;
  const roleTypeSelectItems = [
    { value: User.ERole.staff, label: capitalize(User.ERole.staff) },
    ...(userCanCreateSchedulers
      ? [{ value: User.ERole.scheduler, label: capitalize(User.ERole.scheduler) }]
      : []),
  ];

  const { data: units = [] } = useUnitQuery();
  const staffTypes = useAppConfigQuery().data?.staffTypes;
  const staffTypeItems = useMemo(
    () =>
      map(staffTypes, (staffType) => ({
        label: staffType.name + (staffType.description ? ` (${staffType.description})` : ""),
        value: staffType.name,
      })),
    [staffTypes],
  );

  const { data: allStaffAttributes = [] } = useAllStaffAttributesQuery();
  const allStaffAttributesByKey = useAllStaffAttributesByKey();
  const allStaffAttributeNamesByKey: Record<string, string> = Object.keys(
    allStaffAttributesByKey || {},
  ).reduce(
    (result, key) => {
      result[key] = allStaffAttributesByKey[key]?.name || "";
      return result;
    },
    {} as Record<string, string>,
  );

  const shiftOptions = useCustomSelectShiftsForUnit();
  const allUnitsEligible = watch("unitAssignment") as Uuid[];
  const allUnitsEligibleCustomSelect = units.filter((unit) => allUnitsEligible?.includes(unit.id));
  const allStaffAttributesFilteredByUnit = allStaffAttributes.filter((attribute) =>
    allUnitsEligible?.includes(attribute.unitId),
  );

  // Rule set related
  const { data: ruleSets } = useListPreferenceRequirementRuleSetsQuery(
    { unitIds: [homeUnitId || NOT_EXISTING_UUID] },
    { skip: !homeUnitId },
  );
  const ruleSetDropdownItems = useMap(ruleSets, ruleSetToOption);
  const homeUnitRosterId = userData?.rosters.find((roster) => roster.unitId === homeUnitId)?.id;
  // Grab user rule set and set them
  useEffect(() => {
    if (ruleSets && homeUnitRosterId) {
      const userRuleSets = ruleSets.filter((ruleSet) =>
        ruleSet.rosterRuleSets.find((rosterRuleSet) => rosterRuleSet.rosterId === homeUnitRosterId),
      );
      setValue("preferenceRequirementRuleSetIds", map(userRuleSets, "id"));
    }
  }, [ruleSets, homeUnitRosterId, setValue]);

  return (
    <Grid
      item
      container
      gap={2}
      flexDirection="column"
      mt={2}
      pt="0.3rem"
      maxHeight="50vh"
      flexWrap="nowrap"
      overflow="auto"
    >
      {multipleUserRoles && (
        <CustomMultiSelectControlled
          sx={regularSizedInputSx}
          label="Role Types*"
          name="roleTypes"
          fullWidth
          errors={errors}
          items={roleTypeSelectItems}
          value={(watch("roleTypes") || []) as string[]}
          onChange={(name, selectedValues) => {
            setValue(name, [...new Set([...defaultStaffRoleTypes, ...selectedValues])]);
          }}
          keyToNameObject={roleTypeKeysToNameObject}
        />
      )}
      <CustomSelectControlled
        label="Staff Type*"
        name="staffTypeName"
        control={control}
        fullWidth
        errors={errors}
        items={staffTypeItems}
      />
      <CustomInputControlled
        label="First name*"
        name="firstName"
        control={control}
        errors={errors}
      />
      <CustomInputControlled label="Last name*" name="lastName" control={control} errors={errors} />
      <CustomInputControlled
        disabled={!isUserEmailChangeEnabled}
        label="E-mail address*"
        name="email"
        control={control}
        errors={errors}
      />
      <CustomInputControlled
        label="Phone number*"
        name="phoneNumber"
        control={control}
        errors={errors}
      />
      <CustomSelectControlled
        label="Unit Eligibility*"
        name="unitAssignment"
        control={control}
        fullWidth
        errors={errors}
        items={units}
        multiple
      />
      {/* disable if is not admin and is edit modal */}
      <CustomSelectControlled
        label="Home Unit*"
        name="homeUnitId"
        control={control}
        fullWidth
        errors={errors}
        items={allUnitsEligibleCustomSelect}
      />
      {changedHomeUnitId && (
        <Typography variant="small" sx={{ marginTop: "-12px", color: warning }}>
          {homeUnitMsg}
        </Typography>
      )}
      <CustomSelect<IPreferenceRequirementRuleSet>
        multiple
        label="Shift Requirement"
        MenuProps={{ className: "shift-requirement-dropdown-menu" }}
        variant="outlined"
        // variant="filled"
        items={ruleSetDropdownItems}
        onChange={(event) => {
          const newRuleSetIds = [event.target.value].flat();
          setValue("preferenceRequirementRuleSetIds", newRuleSetIds);
        }}
        optionRenderComponent={(ruleSet) => <RuleSetLabel ruleSet={ruleSet?.item} />}
        renderMultiSelectedValue={(ruleSet) => <RuleSetLabel ruleSet={ruleSet?.item} />}
        value={(watch("preferenceRequirementRuleSetIds") || []) as Uuid[]}
      />
      <CustomSelectControlled
        label="Primary Shift*"
        name="shiftType"
        control={control}
        fullWidth
        errors={errors}
        items={shiftOptions || []}
      />
      <CustomSelectControlled
        label="Working multiple shift types*"
        name="workingMultipleShiftTypes"
        control={control}
        fullWidth
        errors={errors}
        items={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
      />
      <CustomSelectControlled
        label="Preceptor*"
        name="preceptor"
        control={control}
        fullWidth
        errors={errors}
        items={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
      />
      <CustomSelectControlled
        label="On Orientation*"
        name="onOrientation"
        control={control}
        fullWidth
        errors={errors}
        items={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
      />
      {isOnOrientation && (
        <CustomDatePickerControlled
          name="orientationEndDate"
          control={control}
          errors={errors}
          label="Orientation End Date*"
        />
      )}
      <CustomSelectControlled
        label="Employment status*"
        name="employmentType"
        control={control}
        fullWidth
        errors={errors}
        items={rosterUserEmploymentStatus}
      />
      <CustomDatePickerControlled
        name="employmentStartDate"
        control={control}
        errors={errors}
        label="Employment starting date*"
      />
      <CustomDatePickerControlled
        sx={regularSizedInputSx}
        name="contractEndDate"
        control={control}
        errors={errors}
        label={`Contract expiration date`}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
      />
      {!preferenceRequirementsV2 && (
        <>
          <CustomInputControlled
            label="Total shifts per schedule required*"
            name="totalShiftsRequiredPerSchedule"
            control={control}
            errors={errors}
            type="number"
            counter
          />
          <CustomInputControlled
            label="Weekend shifts per schedule required*"
            name="totalWeekendShiftsRequiredPerSchedule"
            control={control}
            errors={errors}
            type="number"
            counter
          />
          <CustomInputControlled
            label="Minimum shifts per week*"
            name="minShiftsPerWeek"
            control={control}
            errors={errors}
            type="number"
            counter
          />
          <CustomInputControlled
            label="Maximum shifts per week*"
            name="maxShiftsPerWeek"
            control={control}
            errors={errors}
            type="number"
            counter
          />
          <CustomInputControlled
            label="Maximum block requests"
            name="maxRequestsOffPerSchedule"
            control={control}
            errors={errors}
            type="number"
            counter
          />
        </>
      )}

      <CustomMultiSelectControlled
        sx={regularSizedInputSx}
        label="Position eligibility"
        name="attributes"
        fullWidth
        errors={errors}
        items={allStaffAttributesFilteredByUnit}
        value={(watch("attributes") || []) as string[]}
        onChange={setValue}
        keyToNameObject={allStaffAttributeNamesByKey}
      />
      <CustomSelectControlled
        label="More than 1 day between shifts?"
        name="moreThanOneDayBetweenShifts"
        control={control}
        fullWidth
        errors={errors}
        items={[
          { label: "Yes", value: true },
          { label: "No", value: false },
        ]}
      />
      <CustomInputControlled
        label="Maximum consecutive shifts"
        name="maxConsecutiveShifts"
        control={control}
        errors={errors}
        type="number"
        counter
      />
    </Grid>
  );
};

const regularSizedInputSx = {
  ".MuiInputBase-input": {
    padding: "8.5px 0 8.5px 14px !important",
  },
  ".MuiInputLabel-root": {
    top: "-7px",
  },
} as const;
