import { filter, get, round } from "lodash";

import { Box, Grid, Typography } from "@mui/material";

import { Schedule, StaffCategory, Unit } from "~/api";
import { useAppSelector } from "~/common/hooks/useRedux";
import { Dayjs } from "~/common/packages/dayjs";
import { black, danger, lines, paleAlert } from "~/common/theming/colors";
import { returnStaffedColorForTargetDeprecated } from "~/features/HouseView/hooks/useStaffingTabs";
import { EHVTimeRange } from "~/features/HouseView/store/pageFiltersActions";
import { useAppConfigQuery } from "~/features/User/queries";

import { stripMilliseconds } from "@/common/utils/dates";

import { ITablesData } from "../Table";

import { HeaderCellContainer, ShiftTypeWrapper } from "./HeaderCell.styled";

interface IHeaderCellProps {
  cellDate: Dayjs;
  columnKey: string;
  schedules: Schedule.DTO[];
  tablesData: ITablesData;
  unitId: Unit.DTO["id"];
}

const rangeToTimeMapping = {
  [EHVTimeRange.day7A7P]: { startTime: "07:00:00", endTime: "19:00:00" },
  [EHVTimeRange.night7P7A]: { startTime: "19:00:00", endTime: "07:00:00" },
};

export const HeaderCellMiniView = ({
  cellDate,
  columnKey: _columnKey,
  schedules,
  tablesData,
  unitId,
}: IHeaderCellProps) => {
  const { data: config } = useAppConfigQuery();
  const unitFromConfig = config?.units.find((unit) => unit.id === unitId);
  const dateNeedsAttentionFlag = false;
  const timeRange = useAppSelector((state) => state.houseView.pageFilters.timeRange);
  const selectedStaffCategory = useAppSelector(
    (state) => state.houseView.pageFilters.selectedStaffCategory,
  );
  const selectedShowTargetLevels = useAppSelector(
    (state) => state.houseView.pageFilters.showTargetLevels,
  );
  const timeRangesToShow =
    timeRange === EHVTimeRange.all
      ? ([EHVTimeRange.day7A7P, EHVTimeRange.night7P7A] as const)
      : ([timeRange] as const);

  const timeTargetLevels = unitFromConfig?.configuration?.data?.timeTargetLevels;

  const timeTargetLevelsForCategory =
    timeTargetLevels?.[
      // StaffCategory could be position (position tab), so if not category found for selected category, use the nurse key as default
      (get(StaffCategory.EName, selectedStaffCategory || StaffCategory.EKey.nurse) as
        | StaffCategory.EName
        | undefined) || StaffCategory.EName[StaffCategory.EKey.nurse]
    ];
  const timeTargetLevelsForStaffCategory = filter(
    timeRangesToShow.map((aRange) => {
      const timeRangeAsTime = rangeToTimeMapping[aRange];
      const foundTarget = timeTargetLevelsForCategory?.find(
        (aLevel) =>
          stripMilliseconds(aLevel.startTime) === timeRangeAsTime.startTime &&
          stripMilliseconds(aLevel.endTime) === timeRangeAsTime.endTime,
      );
      return foundTarget;
    }),
  );

  return (
    <HeaderCellContainer
      flexDirection="column"
      alignItems="flex-start"
      sx={{
        height: "52px",
        maxWidth: "26px",
        opacity: 1,
      }}
    >
      <Box
        className="date-wrapper"
        sx={{
          backgroundColor: dateNeedsAttentionFlag ? paleAlert : "transparent",
          paddingTop: "0px",
          borderTop: dateNeedsAttentionFlag ? `1px solid ${danger}` : "1px solid transparent",
        }}
      >
        <Typography
          align="center"
          variant="small"
          display="block"
          sx={{
            marginBottom: "-7px",
            marginTop: "-7px",
            fontSize: "13px",
            color: "black",
          }}
        >
          {/* use no-break-space character \u00A0 to make sure display="block" is still applied */}
          {cellDate.format("DD") === "01" ? cellDate.format("MMM") : "\u00A0"}
        </Typography>
        <Typography
          align="center"
          variant="small"
          display="block"
          sx={{
            marginBottom: "-7px",
            color: "black",
          }}
        >
          {cellDate.format("DD")}
        </Typography>
        <Typography
          align="center"
          variant="small"
          display="block"
          sx={{
            color: "black",
          }}
        >
          {cellDate.format("dd")}
        </Typography>
      </Box>
      <Grid>
        {timeRangesToShow.map((aRange, idx) => {
          // find the schedule with this daykey that is between startDate and endDate
          const dayKey = cellDate.format("YYYY-MM-DD");
          const schedule = schedules.find(
            ({ startDay, endDay, unitId: possibleUnitId }) =>
              startDay <= dayKey && endDay >= dayKey && possibleUnitId === unitId,
          );
          const shiftsByDayByRange = tablesData?.[schedule?.id || ""];
          const count = round(shiftsByDayByRange?.[dayKey]?.[aRange] || 0, 1);
          const minCount = timeTargetLevelsForStaffCategory?.[idx]?.min;
          const backgroundColor = minCount
            ? returnStaffedColorForTargetDeprecated(count, minCount, "white")
            : "transparent";
          return (
            <Grid
              container
              alignItems="center"
              justifyContent="space-around"
              sx={{
                borderTop: `1px solid ${lines}`,
                height: "27px",
                background: backgroundColor,
                borderBottom: `0px solid ${lines}`,
              }}
            >
              <ShiftTypeWrapper>
                <Typography sx={{ fontSize: "0.8rem", color: black }}>
                  {count}
                  {selectedShowTargetLevels && minCount && `/${minCount}`}
                </Typography>
              </ShiftTypeWrapper>
            </Grid>
          );
        })}
      </Grid>
    </HeaderCellContainer>
  );
};
