import { m7DayJs } from "@m7-health/shared-utils";
import { toString } from "lodash";

export const editRequestBody = (isStaffAccount: boolean, getValues: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    contractEndDate,
    employmentStartDate,
    firstName,
    email,
    lastName,
    employmentType,
    onOrientation,
    orientationEndDate,
    id,
    phoneNumber,
    shiftType,
    status,
    unitAssignment,
    preferenceRequirementRuleSetIds,
    externalStaffIdentifier,
    ...rest
  } = getValues();

  const userInfo = {
    firstName,
    id,
    lastName,
    phoneNumber,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    email,
  };

  const staffData = {
    ...userInfo,
    units: unitAssignment,
    staffDetails: {
      ...rest,
      preferenceRequirementRuleSetIds,
      contractEndDate: isStaffAccount
        ? contractEndDate
          ? m7DayJs(contractEndDate).toISOString()
          : null
        : undefined,
      employmentStartDate: isStaffAccount ? m7DayJs(employmentStartDate).toISOString() : undefined,
      onOrientation,
      orientationEndDate:
        isStaffAccount && onOrientation ? m7DayJs(orientationEndDate).toISOString() : undefined,
      employmentType: toString(employmentType) || null,
      status: toString(status) || null,
      shiftType: toString(shiftType) || null,
      externalStaffIdentifier: externalStaffIdentifier?.trim() || null,
    },
  };

  const schedulerData = {
    ...userInfo,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    units: unitAssignment,
  };

  return isStaffAccount ? staffData : schedulerData;
};
