import { useCallback, useEffect, useMemo, useState } from "react";

import { filter, map } from "lodash";

import { Box, Chip, Stack, Typography } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";

import { ERoleIds } from "#/features/User/types";
import {
  IRoster,
  useListRosterQuery,
  useListStaffDetailsQuery,
  useUpdatePreferenceRequirementRuleSetMutation,
} from "@/api";
import { CustomSelect } from "@/common/components";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useCurrentUnitId, useErrors, useKeyBy } from "@/common/hooks";
import { colorFromText } from "@/common/utils/colorFromText";

import { closeModal, openModal } from "../../store";

export const UpdateRuleSetUsersModal = () => {
  const dispatch = useAppDispatch();
  const unitId = useCurrentUnitId();
  const { showSuccess } = useToast();
  const { handleErrors } = useErrors();
  const ruleSet = useAppSelector((state) => state.roster.updateRuleSetUsersModal.ruleSetToUpdate);

  // Data
  const { data: homeUnitUsers } = useListRosterQuery(
    { unitIds: [unitId || NOT_EXISTING_UUID], homeUsers: [true] },
    { skip: !unitId },
  );
  const { data: staffDetails } = useListStaffDetailsQuery({
    unitIds: [unitId || NOT_EXISTING_UUID],
  });
  const staffDetailsByUserId = useKeyBy(staffDetails, "userId");

  // State
  const [dataSelectedRosterIds, setDataSelectedRosterIds] = useState<IRoster["id"][]>([]);
  const [stateRosterIds, setStateRosterIds] = useState<IRoster["id"][]>(dataSelectedRosterIds);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (ruleSet?.id) {
      // TODO: fix roster id type in shared-utils
      setDataSelectedRosterIds(map(ruleSet?.rosterRuleSets, "rosterId") as unknown[] as number[]);
      setStateRosterIds(map(ruleSet?.rosterRuleSets, "rosterId") as unknown[] as number[]);
    }
  }, [ruleSet?.id, ruleSet?.rosterRuleSets]);

  // Options
  const rosterItems = useMemo(
    () =>
      filter(
        homeUnitUsers?.map((roster) => {
          const details = staffDetailsByUserId[roster.userId];
          if (!details) return null;
          if (roster.roleId !== ERoleIds.staff) return null;

          const {
            user: { firstName, lastName },
          } = details;

          return {
            value: roster.id,
            label: `${firstName} ${lastName}`,
          };
        }),
      ),
    [homeUnitUsers, staffDetailsByUserId],
  );

  // Modal content
  const modalContent = !ruleSet ? (
    <></>
  ) : (
    <Box mt={3}>
      <Stack direction="row" alignItems="center" mb={2}>
        <Chip
          label={ruleSet.label}
          size={"small"}
          sx={{
            width: "90px",
            mr: 1,
            backgroundColor: colorFromText(ruleSet.label),
            borderRadius: "5px",
          }}
        />
        <Typography children={` ${ruleSet.name}`} width={"200px"} noWrap />
      </Stack>
      <Typography children={ruleSet.description} sx={{ mb: 3 }} />
      <Box maxWidth={"500px"}>
        <CustomSelect
          multiple
          checked
          limit={15}
          items={rosterItems}
          label="Select Staff to Assign Requirements"
          value={stateRosterIds}
          onChange={(event) => {
            const values = (
              Array.isArray(event.target.value) ? event.target.value : [event.target.value]
            ) as IRoster["id"][];
            setStateRosterIds(values);
          }}
          sx={{ mb: 3 }}
          displayEmpty
        />
      </Box>
    </Box>
  );

  // Actions
  const { mutateAsync: updateRuleSet } = useUpdatePreferenceRequirementRuleSetMutation({});
  const onSave = useCallback(() => {
    if (!ruleSet) return;

    setIsLoading(true);
    updateRuleSet({
      id: ruleSet.id,
      rosterIds: stateRosterIds,
    })
      .then(() => {
        showSuccess("Preferences requirements updated successfully.");
        dispatch(closeModal("updateRuleSetUsersModal"));
        dispatch(openModal("updateRuleSetsModal"));
      })
      .catch(handleErrors)
      .finally(() => setIsLoading(false));
  }, [dispatch, handleErrors, ruleSet, showSuccess, stateRosterIds, updateRuleSet, setIsLoading]);
  const onCancel = useCallback(() => {
    if (window.confirm("Are you sure you want to discard changes?")) {
      dispatch(closeModal("updateRuleSetUsersModal"));
      dispatch(openModal("updateRuleSetsModal"));
    }
  }, [dispatch]);

  if (!ruleSet) return null;

  return (
    <CustomModal
      isOpen={true}
      closeDisabled
      isLoading={isLoading}
      primaryBtnText="Save"
      modalContent={modalContent}
      modalHeaderText={"Assign Requirements to Staff"}
      onSubmit={onSave}
      onSecondaryBtnClick={onCancel}
    />
  );
};
