import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { capitalize } from "lodash";

import { KeyboardArrowDown } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box, Grid, Menu, Typography } from "@mui/material";

import CustomTabs from "~/common/components/Tabs";
import CustomButton from "~/common/components/TrackedComponents/Button";
import { useLogout } from "~/common/hooks/useLogout";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useRegisterServiceWorker } from "~/common/hooks/useRegisterServiceWorker";
import { danger } from "~/common/theming/colors";
import { setTimeOffRequestModalProperties } from "~/features/ShiftSwapRequest/store";
import { useAppTracking } from "~/modules/mixpanel/Context";
import { Mxp } from "~/modules/mixpanel/types";
import { routes } from "~/routes";
import { persistor } from "~/store";

import { setScheduleType } from "#/features/CalendarV2/store";
import { EStaffScheduleTypes } from "#/features/CalendarV2/types";
import { setCurrentRole } from "#/features/User/store";
import { MenuItem } from "@/common/components";
import { useAppFlags } from "@/common/hooks";

import { defaultRoleRoute } from "../../ProtectedRoute/constants";

const tabs = [
  { label: "Current", value: EStaffScheduleTypes.Current },
  { label: "Planning", value: EStaffScheduleTypes.Planning },
];

export const Top = () => {
  const { isAuthenticated } = useAuth0();
  const { serviceWorkerRegistration } = useRegisterServiceWorker();
  const { logout } = useLogout();
  const dispatch = useAppDispatch();
  const [appExperienceAnchorEl, setAppExperienceAnchorEl] = useState<null | HTMLElement>(null);
  const appExperienceMenuIsOpen = Boolean(appExperienceAnchorEl);
  const { userRoles, currentRole } = useAppSelector((state) => ({
    userRoles: state.user.userData.roles,
    currentRole: state.user.currentRole,
  }));
  const { multipleUserRoles } = useAppFlags();
  const navigate = useNavigate();

  const userCanChangeCurrentRole = multipleUserRoles && userRoles.length > 1;
  const track = useAppTracking();

  const { pathname } = useLocation();

  const scheduleType = useAppSelector((state) => state.calendarV2.scheduleType);

  const handleLogOut = useCallback(async () => {
    persistor.pause();
    await persistor.flush().then(() => {
      return persistor.purge();
    });

    void logout();
  }, [logout]);

  const logoClick = async () => {
    datadogRum.addAction("service-worker-registration-update-logo-click");
    Sentry.addBreadcrumb({
      message: "Service worker registration updating via logo click",
    });
    track(Mxp.Event.system, {
      [Mxp.Property.system.area]: "Service Worker",
      [Mxp.Property.system.action]: "Update - logo click",
    });
    await serviceWorkerRegistration?.update();
    window.location.reload();
  };

  const renderContent = useCallback(() => {
    if (!isAuthenticated) return null;

    switch (pathname) {
      case routes.profile?.path:
        return (
          <CustomButton
            onClick={() => void handleLogOut()}
            variant="text"
            style={{ color: danger, fontSize: "1rem" }}
            trackingLabel="Log out"
          >
            <LogoutIcon sx={{ marginRight: "0.875rem" }} />
            <Typography color={danger} variant="button">
              Log out
            </Typography>
          </CustomButton>
        );
      case routes.calendar?.path:
        return (
          <CustomTabs<EStaffScheduleTypes>
            onChange={(newTab) => dispatch(setScheduleType(newTab))}
            pillTabs
            tabs={tabs}
            value={scheduleType}
            width="auto"
          />
        );
      case routes.shiftSwapRequest?.path:
        return (
          <CustomButton
            style={{
              marginLeft: "auto",
              ".MuiTypography-button": { fontSize: "10px" },
              height: "50px",
            }}
            onClick={() => {
              dispatch(
                setTimeOffRequestModalProperties({
                  isOpen: true,
                  readonly: false,
                  entity: undefined,
                }),
              );
            }}
            label="+ New Time Off Request"
          />
        );
    }
  }, [isAuthenticated, pathname, scheduleType, handleLogOut, dispatch]);
  return (
    <Box display="flex" justifyContent="space-between" flexGrow={1} width="100%" maxHeight={"50px"}>
      <Grid
        sx={{ display: "flex" }}
        alignItems={"center"}
        flexDirection={"row"}
        flexWrap={"nowrap"}
      >
        <img
          alt="App Logo"
          style={{ width: "47px" }}
          src="/M7_logo.svg"
          onClick={() => void logoClick()}
        />
        {userCanChangeCurrentRole && (
          <CustomButton
            iconOnly
            startIcon={<KeyboardArrowDown />}
            trackingLabel="app-experience-toggle"
            onClick={(event) => setAppExperienceAnchorEl(event.currentTarget)}
          />
        )}
      </Grid>
      {renderContent()}
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={appExperienceMenuIsOpen}
        anchorEl={appExperienceAnchorEl}
        onClose={() => setAppExperienceAnchorEl(null)}
      >
        {userRoles.map((role, i) => (
          <MenuItem
            trackingLabel={role}
            selected={role === currentRole}
            onClick={() => {
              dispatch(setCurrentRole(role));
              navigate(defaultRoleRoute(role));
              setAppExperienceAnchorEl(null);
            }}
            sx={{ p: 1 }}
            key={i}
          >
            {capitalize(role)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
