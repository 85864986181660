import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

import { styles } from "./Tag.styled";
import { ITagProps } from "./types";

const defaultSx = {};

export const Tag = ({
  backgroundColor = "transparent",
  className,
  disabled,
  icon,
  isLoading,
  onClick,
  text,
  variant = "big",
  fullWidth = false,
  miniView = false,
  miniViewLabel = undefined,
  sx = defaultSx,
}: ITagProps) => {
  // If miniView is true and have a miniViewLabel, don't show an icon- else show the icon (if no label)
  const startIcon = miniView && miniViewLabel ? null : icon;

  const label = (!miniView ? text : miniViewLabel || "") || "";

  return (
    <LoadingButton
      sx={{
        maxWidth: miniView ? "1px" : "unset",
        cursor: onClick ? "pointer" : "default",
        overflow: "hidden",
        backgroundColor,
        "&:hover": { backgroundColor },
        "&:focus": { backgroundColor },
        ...styles,
        ...sx,
      }}
      data-label-length={text?.length || 0}
      className={(className || "") + ` custom-tag ${variant} ${text === null ? "no-text" : ""}`}
      fullWidth={fullWidth}
      loading={isLoading}
      loadingPosition={startIcon ? "start" : undefined}
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled}
      disableRipple={!onClick}
      disableTouchRipple={!onClick}
      disableFocusRipple={!onClick}
    >
      <Typography
        variant="body2"
        className="tag-text"
        sx={{
          color: "inherit",
          fontSize: variant === "small" ? "0.8125" : "0.875rem",
          fontWeight: 500,
          whiteSpace: "nowrap",
          // If label is 2, we always want to show the full text
          //  as it's what is allowed for most abbreviations
          textOverflow: label.length > 2 ? "ellipsis" : "initial",
        }}
      >
        {/** If miniView is true, we don't want to show the text, but we want to show the miniViewLabel if it exists, else nothing */}
        {label}
      </Typography>
    </LoadingButton>
  );
};
