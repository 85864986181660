import { ISODateString, TimeString, Timezone, Unit, YyyyMmDd } from "@m7-health/shared-utils";
import { createSelector } from "reselect";

import {
  RealTimeStaffingTarget,
  TRealTimeStaffingTargetToCreate,
  TRealTimeStaffingTargetToUpdate,
} from "~/api/realTimeStaffingTargets";
import { TRootState } from "~/store";

import { TPositionAsTab } from "#/features/HouseView/hooks/useStaffingTabs";
import { StaffCategory } from "@/api";
import { localDayJs } from "@/common/packages/dayjs";
import { add24Hours, dateStringInTimeRangeGivenTimezone, getTzDayjs } from "@/common/utils/dates";

export type TUpdatedStaffingLevel = Partial<RealTimeStaffingTarget.DTO>;

export const staffingLevelSelector = (unitId: string) =>
  createSelector(
    [(state: TRootState) => state.houseView.staffingLevels.editedStaffingTargets?.[unitId]],
    (staffingLevel) => staffingLevel,
    {
      memoizeOptions: (previousData: object, currentData: object) => {
        return JSON.stringify(previousData) === JSON.stringify(currentData);
      },
    },
  );

/**
 * Retrieves the matching staffing level based on the staffing level matrix.
 *
 * @param staffingLevelMatrix - The staffing level matrix.
 * @param staffCategory - The staff category.
 * @param unitStaffingLevel - The unit staffing level.
 * @returns The matching staffing level.
 */
export const getMatchingStaffingLevel = (
  staffCategoryKeyOrPosition: StaffCategory.EKey | TPositionAsTab,
  unitStaffingLevel: Partial<RealTimeStaffingTarget.DTO> | undefined,
  staffingLevelMatrix?: Unit.TStaffingLevelMatrix | undefined,
) => {
  // get possible staffing levels for the current patient count and category
  const possibleLevels =
    staffingLevelMatrix?.[unitStaffingLevel?.patientCount || 0]?.[staffCategoryKeyOrPosition];

  // for each possible level, if the end time is before the start time, add 24 hours to the end time
  possibleLevels?.forEach((level) => {
    if (level.startTime.localeCompare(level.endTime) > 0) {
      level.endTime = add24Hours(level.endTime);
    }
  });
  let unitStaffingLevelTime: TimeString = localDayJs(unitStaffingLevel?.date).format(
    "HH:mm:ss",
  ) as TimeString;
  // add 24 hours to the unit staffing level time if it is before 7AM
  if (unitStaffingLevelTime.localeCompare("07:00:00") < 0) {
    unitStaffingLevelTime = add24Hours(unitStaffingLevelTime);
  }
  const matchingStaffingLevel = possibleLevels?.find(
    (possibleLevel) =>
      unitStaffingLevelTime.localeCompare(possibleLevel.startTime) >= 0 &&
      unitStaffingLevelTime.localeCompare(possibleLevel.endTime) < 0,
  );
  return matchingStaffingLevel;
};

const timeRangeThatShouldStillBeToday = {
  startTime: "00:00:00" as TimeString,
  endTime: "07:00:00" as TimeString,
};
/**
 * Determines the final staffing target level date based on the given staffing target. ()
 *
 * @param staffingTarget - The staffing target to evaluate.
 * @returns The adjusted date as an ISO string if the staffing target's date falls within
 *          the specified time range (00:00:00 to 07:00:00) (to be considered the next day), or the original date otherwise.
 */
export const getFinalTargetWithCorrectDate = <
  T extends TRealTimeStaffingTargetToCreate | TRealTimeStaffingTargetToUpdate,
>(
  staffingTarget: T,
  selectedDate: YyyyMmDd,
  timezone: Timezone,
): T => {
  const targetDateInTz = getTzDayjs(staffingTarget.date, timezone);
  const selectedDateObj = getTzDayjs(selectedDate, timezone);

  // Check if the target date is within the time range that should still be considered "today"
  const isInEarlyMorningRange = dateStringInTimeRangeGivenTimezone(
    timeRangeThatShouldStillBeToday,
    staffingTarget.date,
    timezone,
  );

  // If the target date is in the early morning range and it's the day after the selected date,
  // we don't need to adjust it
  if (isInEarlyMorningRange && targetDateInTz.isAfter(selectedDateObj, "day")) {
    return staffingTarget;
  }

  // If the target date is in the early morning range and it's on the selected date,
  // we need to adjust it to the next day
  if (isInEarlyMorningRange && targetDateInTz.isSame(selectedDateObj, "day")) {
    return {
      ...staffingTarget,
      // can use localDayJs here because we care about the utc date
      // could use targetDateInTz.add(1, "day").toISOString() as ISODateString
      // ...but would be less efficient because it would require a timezone conversion, then back to UTC (ISODateString)
      date: localDayJs(staffingTarget.date).add(1, "day").toISOString() as ISODateString,
    };
  }

  // In all other cases, return the original staffing target
  return staffingTarget;
};
