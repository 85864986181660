import { useSearchParams } from "react-router-dom";

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { isInteger } from "lodash";

import { useAppSelector } from "~/common/hooks/useRedux";

import {
  getRosterApi,
  getSchedulerRosterApi,
  getUnassignedSchedulers,
  getUserApi,
  IGetRoster,
} from "../api";
import { ERosterUserOptions } from "../StaffRoster/StaffRoster";

/**
 * Custom hook to fetch the roster for a selected unit WITH PAGINATION.
 *
 * @param {Array<boolean>} suspended - Array indicating the suspension status of users to be fetched.
 * ([false] means users not suspended, [false, true] means all users, [true] means only suspended users)
 * @param {ERosterUserOptions} userOption - Option to filter users based on specific criteria.
 *
 * @returns {object} - The result of the `useQuery` hook from `react-query`, containing the roster data and query status.
 *
 * @example
 * const { data, isLoading, error } = useRosterQuery([true], ERosterUserOptions.active);
 *
 * @remarks
 * This hook fetches the roster data for a selected unit with pagination support.
 * The query is enabled only if a `selectedUnit` is available.
 */
export const useRosterQuery = (suspended: Array<boolean>, userOption: ERosterUserOptions) => {
  const selectedUnit = useAppSelector((state) => state.common.currentUnit);
  const pageSize = useAppSelector((state) => state.roster.rosterTable.pageSize);

  const [searchParams] = useSearchParams();
  const pageParam = Number(searchParams.get("page"));

  const page = !isNaN(pageParam) && isInteger(pageParam) && pageParam >= 1 ? pageParam : 1;
  const skip = (page - 1) * pageSize;

  const params: IGetRoster = { skip, unitId: selectedUnit?.id, take: pageSize };
  if (suspended) params.suspended = suspended;
  if (userOption) params.userOption = userOption;

  return useQuery({
    queryKey: ["roster", params],
    queryFn: () => getRosterApi(params),
    placeholderData: keepPreviousData,
    enabled: !!selectedUnit?.id,
  });
};

/**
 * Custom hook to fetch the scheduler roster for a selected facility with pagination.
 *
 * @param {Array<boolean>} suspended - Array indicating the suspension status of users to be fetched.
 * ([false] means users not suspended, [false, true] means all users, [true] means only suspended users)
 * @returns {object} - The result of the `useQuery` hook from `react-query`, containing the scheduler roster data and query status.
 *
 * @example
 * const { data, isLoading, error } = useSchedulerRosterQuery([true]);
 *
 * @remarks
 * This hook fetches the scheduler roster data for a selected facility with pagination support.
 * The query is enabled only if a `selectedFacilityId` is available.
 */
export const useSchedulerRosterQuery = (suspended: Array<boolean>) => {
  const { selectedFacilityId } = useAppSelector((state) => state.houseView.pageFilters);
  const pageSize = useAppSelector((state) => state.roster.rosterTable.pageSize);

  const [searchParams] = useSearchParams();
  const pageParam = Number(searchParams.get("page"));

  const page = !isNaN(pageParam) && isInteger(pageParam) && pageParam >= 1 ? pageParam : 1;
  const skip = (page - 1) * pageSize;

  const params: IGetRoster = { skip, facilityId: selectedFacilityId || undefined, take: pageSize };
  if (suspended) params.suspended = suspended;

  return useQuery({
    queryKey: ["scheduler-roster", params],
    queryFn: () => getSchedulerRosterApi(params),
    placeholderData: keepPreviousData,
    enabled: !!selectedFacilityId,
  });
};

/**
 * Custom hook to fetch the entire roster for a selected unit.
 *
 * @param {Array<boolean>} [suspended=[false]] - Array indicating the suspension status of users to be fetched.
 * ([false] means users not suspended, [false, true] means all users, [true] means only suspended users)
 * @param {ERosterUserOptions} [userOption=ERosterUserOptions.all] - Option to filter users based on specific criteria.
 *
 * @returns {object} - The result of the `useQuery` hook from `react-query`, containing the roster data and query status.
 *
 * @example
 * const { data, isLoading, error } = useRosterQueryAll([true], ERosterUserOptions.active);
 *
 * @remarks
 * This hook fetches all staff in the roster from an endpoint that requires taking a maximum number of staff.
 * The `MAX_STAFF_IN_ROSTER` constant is set to 10000 to ensure all staff are fetched.
 * The query is enabled only if a `selectedUnit` is available.
 */
export const useRosterQueryAll = (
  suspended: Array<boolean> = [false],
  userOption: ERosterUserOptions = ERosterUserOptions.all,
) => {
  const selectedUnit = useAppSelector((state) => state.common.currentUnit);
  // Using this variable to get all staff in roster from endpoint that requires taking a MAX number of staff
  const MAX_STAFF_IN_ROSTER = 10000;
  const params: IGetRoster = { unitId: selectedUnit?.id, take: MAX_STAFF_IN_ROSTER };
  params.suspended = suspended;
  params.userOption = userOption;

  return useQuery({
    queryKey: ["roster", params],
    queryFn: () => getRosterApi(params),
    placeholderData: keepPreviousData,
    enabled: !!selectedUnit?.id,
  });
};

export const useSchedulersQuery = () => {
  const selectedUnit = useAppSelector((state) => state.common.currentUnit);

  return useQuery({
    queryKey: ["schedulers"],
    queryFn: () => getUnassignedSchedulers(selectedUnit?.id),
    placeholderData: keepPreviousData,
  });
};

export const useUserQuery = (id: string, enabled: boolean) => {
  return useQuery({
    queryKey: ["users", id],
    queryFn: () => getUserApi(id),
    enabled,
  });
};
