import {
  dayShift,
  dayShiftDark,
  educationShift,
  lightPurple,
  schoolShift,
  vacationShift,
} from "../theming";

const colors = [dayShift, dayShiftDark, vacationShift, educationShift, schoolShift, lightPurple];

// Transform string to hash. Idempotent.
const hashStringToNumber = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return hash;
};

/**
 *
 * @param text
 * @returns a color from the colors array based on the hash of the text
 *
 * This function is used to generate a color based on the text.
 *  the same string will always return the same color.
 */
export const colorFromText = (text: string) => {
  const hash = hashStringToNumber(text);
  return colors[Math.abs(hash) % colors.length];
};
