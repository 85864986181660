import {
  PreferenceRequirementRuleSet as SharedPreferenceRequirementRuleSet,
  TValidityCheckResults,
  Uuid,
} from "@m7-health/shared-utils";

import { BULK_DELETE_KEY } from "@/common/constants";

import { APIElementResponse, APIListResponse, SDTO } from "../_shared/types";
import { IRoster } from "../roster";
import { IUnit } from "../unit";

export type IPreferenceRequirementRuleSet = PreferenceRequirementRuleSet.DTO;
export type IPreferenceRequirementRule = PreferenceRequirementRuleSet.RuleDTO;
export type IPreferenceRequirementResults = TValidityCheckResults[Uuid]["checks"][Uuid];

export namespace PreferenceRequirementRuleSet {
  export interface RuleDTO extends SharedPreferenceRequirementRuleSet.RuleDTO {}

  export interface RosterRuleSetDTO extends SDTO.base, SDTO.withTimestamps, SDTO.softDeletable {
    preferenceRequirementRuleSetId: DTO["id"];
    rosterId: IRoster["id"];
  }

  export interface DTO extends SharedPreferenceRequirementRuleSet.DTO {}

  export namespace API {
    export const ROOT_PATH = "/preference-requirement-rule-set";
    export namespace List {
      export const PATH = ROOT_PATH;
      export interface QueryParams {
        unitIds?: IUnit["id"][];
      }

      export type Response = APIListResponse<DTO>;
    }

    export namespace Create {
      export const PATH = ROOT_PATH;
      export type QueryParams = TPreferenceRequirementRuleSetParams;
      export type Response = APIElementResponse<DTO>;
    }

    export namespace Update {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;

      export type QueryParams = Partial<TPreferenceRequirementRuleSetParams> & { id: DTO["id"] };
      export type Response = APIElementResponse<DTO>;
    }

    export namespace Delete {
      export const PATH = (params: QueryParams) => `${ROOT_PATH}/${params.id}`;

      export type QueryParams = { id: DTO["id"] };
      export type Response = APIElementResponse<{ id: DTO["id"] }>;
    }
  }
}

type TRuleParams = Pick<
  IPreferenceRequirementRule,
  | "frequencyValueMin"
  | "frequencyValueMax"
  | "frequencyStep"
  | "targetType"
  | "targetShiftTypeKeys"
  | "label"
>;

type TRuleToCreate = {
  id: never;
} & TRuleParams;
type TRuleToUpdate = {
  id: IPreferenceRequirementRule["id"];
} & Partial<TRuleParams>;
type TRuleToDelete = {
  [BULK_DELETE_KEY]: true;
  id: IPreferenceRequirementRule["id"];
};

type TPreferenceRequirementRule = TRuleToCreate | TRuleToUpdate | TRuleToDelete;
type TPreferenceRequirementRuleSetParams = Pick<
  IPreferenceRequirementRuleSet,
  "unitId" | "label" | "name" | "description" | "globalOperator"
> & {
  rosterIds?: IRoster["id"][];
  rules?: TPreferenceRequirementRule[];
};
