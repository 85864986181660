import { useMemo } from "react";

import { m7DayJs, Unit } from "@m7-health/shared-utils";

import { IPreferenceRequirementRuleSet, IUnit } from "@/api";

export const useIsValid = (ruleSet?: IPreferenceRequirementRuleSet) => {
  const hasAtLeastOneRule = !!ruleSet?.preferenceRequirementRules?.length;
  const hasName = !!ruleSet?.name;
  const hasLabel = !!ruleSet?.label;

  const rulesValid = ruleSet?.preferenceRequirementRules?.every((rule) => {
    const ruleHasLabel = !!rule.label;
    const hasFrequencyMinValue = rule.frequencyValueMin >= 0;
    const hasFrequencyMaxValue = rule.frequencyValueMax >= 0;
    const maxIsGreaterThanMin = rule.frequencyValueMax >= rule.frequencyValueMin;
    const hasShifts = !!rule.targetShiftTypeKeys?.length;
    return (
      ruleHasLabel &&
      hasFrequencyMinValue &&
      hasFrequencyMaxValue &&
      hasShifts &&
      maxIsGreaterThanMin
    );
  });

  return hasAtLeastOneRule && hasName && hasLabel && rulesValid;
};

export const useHumanReadableWeekendBoundaries = (unit: IUnit | null) => {
  const weekendConfig =
    unit?.configuration?.settings?.weekendTimeBoundaries ||
    Unit.Config.defaultValues.settings.weekendTimeBoundaries;

  return useMemo(() => {
    const weekendDays = unit?.weekendDays || [];
    if (!weekendDays.length) return "";

    const fromDayOfWeek = m7DayJs().day(weekendDays[0]!).format("dddd");
    const toDayOfWeek = m7DayJs()
      .day(weekendDays[weekendDays.length - 1]!)
      .format("dddd");

    const fromTime = m7DayJs(`2022-01-01: ${weekendConfig.startTime}`).format("h:mm a");
    const toTime = m7DayJs(`2022-01-01: ${weekendConfig.endTime}`).format("h:mm a");

    return `(${fromDayOfWeek} ${fromTime} to ${toDayOfWeek} ${toTime})`;
  }, [weekendConfig, unit?.weekendDays]);
};
