import Tag from "~/common/components/Tag";
import { iconComponentForShift } from "~/common/constants";
import { theme } from "~/common/theming";
import { TimeString } from "~/common/types";
import { TimeStringToStandardTime, timeAdd } from "~/common/utils/dates";
import { useShiftsByScheduleQuery, useShiftsQuery } from "~/routes/queries";

import { IShiftProps } from "./types";

/** @deprecated, we should try to move to ShiftV2 */
export const Shift = ({
  // fine tuning elements of the component
  className,
  disabled = false,
  isLoading = false,
  hasLabel = true,
  withShiftSuffix = false,
  isStaffShift = false,
  fullWidth = false,
  hideIcon = false,
  miniView = false,
  // hoursInLabel is for when you want to display the hours in the label after the Shift Name
  hoursInLabel = undefined,
  // hoursAsLabel is for when you want to display the hours in the label instead of the Shift Name
  hoursAsLabel = undefined,

  // 3 sizes: small, medium, big
  variant = "big",
  hoursVariant = undefined,

  // customization
  sx = {},
  customLabel = undefined,
  customColor = undefined,
  customIcon = undefined,

  // Actions
  onClick,

  // Data
  /* Shift type
   * Or shift type is provided, or option is provided, with
   *  optional unitId to get the shift type from, fallback on selected unitId.
   */
  shiftType = undefined,

  option = undefined,
  scheduleId = undefined,

  // Override shiftType data with working shift data
  customStartTime = undefined,
  customDuration = undefined,
}: IShiftProps) => {
  let color, defaultLabel;

  const { data: shiftsFromSchedule } = useShiftsByScheduleQuery(scheduleId);
  const { data: allShiftOptionsGlobal } = useShiftsQuery();

  // If option provided (Shift type Key), go retrieve it
  if (option) {
    if (scheduleId) {
      shiftType = shiftsFromSchedule?.[option];
      // If no schedule shift type, means it's an old schedule.
      //  so corresponding shift types are in the global list
      shiftType ||= allShiftOptionsGlobal?.[option];
    }
    shiftType ||= allShiftOptionsGlobal?.[option];
  }

  // If no shift type was found and no option were provided, nothing to do
  if (!shiftType && !option) return null;

  // Setup main attributes - color, label, icon
  const {
    scheduleViewColor = "#FFFFFF",
    scheduleViewDisplayName = "Loading...",
    staffViewColor = "#FFFFFF",
    staffViewDisplayName = "Loading...",
    printAbbreviation,
  } = shiftType || {};

  if (isStaffShift) {
    color = staffViewColor;
    defaultLabel = staffViewDisplayName;
  } else {
    color = scheduleViewColor;
    defaultLabel = scheduleViewDisplayName;
  }
  defaultLabel = withShiftSuffix ? `${defaultLabel} Shift` : defaultLabel;

  if (customColor) color = customColor;

  hoursAsLabel ??= !!(customDuration || customStartTime);

  const [startTime, endTime] = (() => {
    if (!hoursAsLabel && !hoursInLabel) return [undefined, undefined];

    let start: TimeString;
    let end: TimeString;
    if (customStartTime) {
      start = customStartTime;
    } else if (shiftType?.startTime) {
      start = shiftType?.startTime;
    } else {
      return [undefined, undefined];
    }

    if (customDuration) {
      end = timeAdd(start, customDuration);
    } else if (shiftType?.durationSeconds) {
      end = timeAdd(start, shiftType?.durationSeconds);
    } else {
      return [undefined, undefined];
    }

    return [start, end];
  })();

  if (startTime && endTime && shiftType?.isPaidShift) {
    const labelTime = `${TimeStringToStandardTime(
      startTime,
      hoursVariant,
    )}-${TimeStringToStandardTime(endTime, hoursVariant)}`;
    if (hoursAsLabel) defaultLabel = labelTime;
    else if (hoursInLabel) defaultLabel = `${defaultLabel} (${labelTime})`;
  }

  let icon;
  if (!hideIcon) {
    if (customIcon) icon = customIcon;
    else {
      // allow to hide icon if we want by setting muiIconClassName to None
      // if muiIconClassName is not set or null or unknown, will fallback on default icon
      if (shiftType?.muiIconClassName === "None") {
        icon = undefined;
      } else {
        if (shiftType && "customJSXelementAsIcon" in shiftType) {
          icon = shiftType?.customJSXelementAsIcon;
        } else {
          const Icon = iconComponentForShift(shiftType?.muiIconClassName);
          icon = <Icon sx={{ color: shiftType?.iconColor }} />;
        }
      }
    }
  }

  return (
    <Tag
      sx={{
        // If color is dark, use white text
        color: theme.palette.getContrastText(color),
        p: { "-webkit-text-fill-color": "initial" },
        ...sx,
      }}
      backgroundColor={color}
      className={["shift-tile", shiftType?.key || option, variant, className].join(" ")}
      disabled={disabled}
      icon={icon}
      isLoading={isLoading}
      onClick={onClick}
      text={hasLabel ? customLabel || defaultLabel : null}
      variant={variant}
      fullWidth={fullWidth}
      miniView={miniView}
      miniViewLabel={printAbbreviation}
    />
  );
};
