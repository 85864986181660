import { YyyyMmDd, THouseViewTimeRange, TimeString } from "@m7-health/shared-utils";
import { PayloadAction } from "@reduxjs/toolkit";

import { StaffCategory } from "~/api";
import { IFacility } from "~/features/User/types";
import { IUnitBasic } from "~/routes/api/types";

import { THouseViewState } from ".";

/** @deprecated but still to be used for Time Range Modal */
// TODO: Remove this completely
export enum EHVTimeRange {
  "all" = "all",
  "day7A7P" = "day7A7P",
  "night7P7A" = "night7P7A",
}

export const DEFAULT_CUSTOM_TIME_RANGE: THouseViewTimeRange = {
  startTime: "07:00:00" as TimeString,
  endTime: "19:00:00" as TimeString,
  customAbbreviation: "All",
};

export type PageFilters = {
  selectedDate: YyyyMmDd | null;
  selectedUnitId: IUnitBasic["id"] | null;
  selectedFacilityId: IFacility["id"] | null;
  selectedStaffCategory: StaffCategory.EKey | string | null;
  timeRange: EHVTimeRange;
  customTimeRange: THouseViewTimeRange | null;
  isMultiWeekView: boolean;
  showTargetLevels: boolean;
  // Which units to filter by globally on the page
  // When all units are shown, this should be an empty array
  unitIds: IUnitBasic["id"][];
};

export const PageFiltersInitialValues: PageFilters = {
  selectedDate: null,
  selectedFacilityId: null,
  selectedUnitId: null,
  selectedStaffCategory: null,
  timeRange: EHVTimeRange.day7A7P,
  isMultiWeekView: false,
  showTargetLevels: true,
  unitIds: [],
  customTimeRange: null,
};

export const PageFiltersActions = {
  selectDate({ pageFilters }: THouseViewState, action: PayloadAction<PageFilters["selectedDate"]>) {
    pageFilters.selectedDate = action.payload;
  },
  setUnitsFilter({ pageFilters }: THouseViewState, action: PayloadAction<IUnitBasic["id"][]>) {
    pageFilters.unitIds = action.payload;
  },
  selectIsMultiWeekView(
    { pageFilters }: THouseViewState,
    action: PayloadAction<PageFilters["isMultiWeekView"]>,
  ) {
    pageFilters.isMultiWeekView = action.payload;
  },
  selectFacility(
    { pageFilters }: THouseViewState,
    action: PayloadAction<PageFilters["selectedFacilityId"]>,
  ) {
    pageFilters.selectedFacilityId = action.payload;
  },
  selectUnit(
    { pageFilters }: THouseViewState,
    action: PayloadAction<PageFilters["selectedUnitId"]>,
  ) {
    pageFilters.selectedUnitId = action.payload;
  },
  selectStaffCategory: (
    { pageFilters }: THouseViewState,
    action: PayloadAction<StaffCategory.EKey | string>,
  ) => {
    pageFilters.selectedStaffCategory = action.payload;
  },
  selectTimeRange: ({ pageFilters }: THouseViewState, action: PayloadAction<EHVTimeRange>) => {
    pageFilters.timeRange = action.payload;
  },
  selectCustomTimeRange: (
    { pageFilters }: THouseViewState,
    action: PayloadAction<THouseViewTimeRange>,
  ) => {
    pageFilters.customTimeRange = action.payload;
  },
  selectShowTargetLevels: ({ pageFilters }: THouseViewState, action: PayloadAction<boolean>) => {
    pageFilters.showTargetLevels = action.payload;
  },
};
