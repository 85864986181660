import React, { FC } from "react";

import { Chip, ChipProps } from "@mui/material";

import { IShiftIncentiveLevel } from "@/api";
import { theme, white } from "@/common/theming";
import { emptySx } from "@/common/types";

export const ShiftIncentiveLevel: FC<
  ChipProps & {
    shiftIncentiveLevel?: IShiftIncentiveLevel;
    showNone?: boolean;
  }
> = React.forwardRef(({ shiftIncentiveLevel, sx, showNone, ...otherProps }, ref) => {
  if (!shiftIncentiveLevel && !showNone) return null;

  const label = shiftIncentiveLevel?.label || "None";
  const backgroundColor = shiftIncentiveLevel?.color || white;
  const color = theme.palette.getContrastText(backgroundColor);

  return (
    <Chip
      ref={ref}
      label={label}
      sx={{
        width: "120px",
        backgroundColor,
        color,
        borderRadius: "5px",
        "-webkit-text-fill-color": color,
        ...(sx || emptySx),
      }}
      {...otherProps}
    />
  );
});
