import { useRef, useState } from "react";

import { useMutation } from "@tanstack/react-query";

import { Sms } from "@mui/icons-material";
import { IconButton, TextField } from "@mui/material";

import CustomModal from "~/common/components/Modal";
import { useErrors } from "~/common/hooks/useErrors";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";
import { sendMassSmsApi } from "~/features/Roster/api";
import { setSingleSendSmsModalOpen, setSingleSendSmsModalUser } from "~/features/Roster/store";

import { ISingleSendSMSModalData } from "#/features/Roster/types";
import { useIsAdmin, useIsScheduler } from "@/common/hooks";
import { emptySx, TSx } from "@/common/types";

const Button = ({ data, sx = emptySx }: { data: ISingleSendSMSModalData; sx?: TSx }) => {
  const dispatch = useAppDispatch();

  const { firstName, lastName, id } = data;
  const isAdmin = useIsAdmin();
  const isScheduler = useIsScheduler();
  const hasSingleSendSmsPermission = isAdmin || isScheduler;

  const handleSingleSendSmsModal = () => {
    dispatch(setSingleSendSmsModalOpen(true));
    dispatch(
      setSingleSendSmsModalUser({
        firstName,
        lastName,
        id,
      }),
    );
  };

  if (!hasSingleSendSmsPermission) return <></>;

  return (
    <IconButton onClick={handleSingleSendSmsModal}>
      <Sms sx={sx} />
    </IconButton>
  );
};

const Modal = () => {
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const { showSuccess } = useToast();
  const selectedUnit = useAppSelector((state) => state.common.currentUnit);

  const isOpen = useAppSelector((state) => state.roster.singleSendSmsModal.isOpen);
  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: sendMassSmsApi,
    onSuccess: () => {
      dispatch(setSingleSendSmsModalOpen(false));
      showSuccess("SMS sent successfully");
      message.current = "";
    },
    onError: handleErrors,
  });

  const [contentIsEmpty, setContentIsEmpty] = useState(true);
  const message = useRef("");
  const selectedUser = useAppSelector(
    (state) => state.roster.singleSendSmsModal.preSelectedUserToSms,
  );
  if (!selectedUser) {
    dispatch(setSingleSendSmsModalOpen(false));
    return null;
  }
  const confirmSending = () => {
    if (
      window.confirm(
        `You are about to send an SMS to ${selectedUser.firstName} ${selectedUser.lastName}.\n`,
      )
    ) {
      const userIds = [selectedUser.id];
      mutate({
        userIds: userIds,
        messageContent: message.current,
      });
    }
  };

  const handleSecondaryBtnClick = () => {
    dispatch(setSingleSendSmsModalOpen(false));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      primaryBtnText="Send SMS"
      modalHeaderText={`Send SMS to ${selectedUser.firstName} ${selectedUser.lastName}`}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      onSubmit={confirmSending}
      primaryDisabled={isLoading || contentIsEmpty}
      variant="warning"
      variantText={
        <>
          You are about to send an SMS to{" "}
          <u>
            <b>
              {selectedUser.firstName} {selectedUser.lastName}{" "}
            </b>
          </u>{" "}
          <br />
          <i style={{ fontWeight: "400" }}>
            Text will be sent from {selectedUnit?.facility?.twilioPhoneNumber}
          </i>
        </>
      }
      modalContent={
        <TextField
          fullWidth
          multiline
          onChange={(e) => {
            message.current = e.target.value;
            setContentIsEmpty(e.target.value === "");
          }}
          placeholder="Enter your message here"
          minRows={10}
        />
      }
    />
  );
};

export const SendSingleSms = {
  Modal,
  Button,
};
