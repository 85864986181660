import { createSlice } from "@reduxjs/toolkit";

import { Schedule } from "@/api";

import {
  ICxDashboardState,
  IScheduleColumnUpdatesState,
  IScheduleDetailsTableSortByColumn,
} from "./types";

import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: ICxDashboardState = {
  scheduleDetailsTable: {
    pageSize: 20,
    updates: {},
    filters: {
      statuses: [Schedule.EStatus.collecting, Schedule.EStatus.balancing],
      units: [],
      facilities: [],
    },
    sortByColumn: undefined,
  },
  modals: {
    sendPreferenceReminderToStaffModal: {
      isOpen: false,
    },
    filtersModal: {
      isOpen: false,
    },
  },
};

export const cxDashboardSlice = createSlice({
  name: "cxDashboard",
  initialState,
  reducers: {
    setScheduleDetailsTablePageSize: (state, action: PayloadAction<number>) => {
      state.scheduleDetailsTable.pageSize = action.payload;
    },
    setScheduleDetailsTableUpdates: (
      state,
      action: PayloadAction<{ scheduleId: string } & IScheduleColumnUpdatesState>,
    ) => {
      const { scheduleId, ...updatedColumns } = action.payload;
      state.scheduleDetailsTable.updates[scheduleId] = {
        ...state.scheduleDetailsTable.updates[scheduleId],
        ...updatedColumns,
      };
    },
    undoScheduleDetailsTableUpdates: (state) => {
      state.scheduleDetailsTable.updates = {};
    },
    setSendPreferenceReminderToStaffModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.sendPreferenceReminderToStaffModal.isOpen = action.payload;
    },
    setFiltersModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.filtersModal.isOpen = action.payload;
    },
    setStatusesFilter: (state, action: PayloadAction<Schedule.EStatus[]>) => {
      state.scheduleDetailsTable.filters.statuses = action.payload;
    },
    setUnitsFilter: (state, action: PayloadAction<string[]>) => {
      state.scheduleDetailsTable.filters.units = action.payload;
    },
    setFacilitiesFilter: (state, action: PayloadAction<string[]>) => {
      state.scheduleDetailsTable.filters.facilities = action.payload;
    },
    setSortByColumn: (
      state,
      action: PayloadAction<IScheduleDetailsTableSortByColumn | undefined>,
    ) => {
      state.scheduleDetailsTable.sortByColumn = action.payload;
    },
  },
});

export const {
  setScheduleDetailsTablePageSize,
  setScheduleDetailsTableUpdates,
  setSendPreferenceReminderToStaffModalIsOpen,
  setFiltersModalIsOpen,
  setStatusesFilter,
  setUnitsFilter,
  setFacilitiesFilter,
  setSortByColumn,
  undoScheduleDetailsTableUpdates,
} = cxDashboardSlice.actions;
export default cxDashboardSlice.reducer;
