import { useCallback, useMemo } from "react";

import { m7DayJs } from "@m7-health/shared-utils";
import { keyBy, keys, map } from "lodash";

import { useAppConfigQuery } from "~/features/User/queries";

import { useRosterQuery } from "#/features/Roster/queries";
import { ERosterUserOptions } from "#/features/Roster/StaffRoster/StaffRoster";
import { IStaffWithDetailsDto } from "#/features/Roster/types";
import { User } from "@/api";
import { NOT_EXISTING_UUID } from "@/common/constants";

import { useCurrentSelectedUnitId } from "./useCurrentUnitId";

export const useDownloadRoster = () => {
  // ** Queries -- data
  // Units
  const { data: { units = [] } = {} } = useAppConfigQuery();
  const unitsById = useMemo(() => keyBy(units, "id"), [units]);
  const currentUnitId = useCurrentSelectedUnitId();
  const unitId = currentUnitId || NOT_EXISTING_UUID;
  const unit = unitsById[unitId];

  const { data } = useRosterQuery([false], ERosterUserOptions.home);
  const staffItems: IStaffWithDetailsDto[] = useMemo(() => {
    const { data: items = [] } = data || {};
    return items.filter((item) => item.userType === User.ERole.staff);
  }, [data]) as IStaffWithDetailsDto[];

  return useCallback(() => {
    // File name: survey-responses__{unitName}__{date}.csv
    const fileName = `roster__${unit?.name || ""}__${m7DayJs().format("YYYY-MM-DD")}.csv`;

    // staff headers
    const staffHeaders: {
      [key: string]: (staff: IStaffWithDetailsDto) => string;
    } = {
      "First name": ({ firstName }) => firstName,
      "Last name": ({ lastName }) => lastName,
      "Staff type": ({ staffTypeName }) => staffTypeName,
      Email: ({ email }) => email,
      "Phone number": ({ phoneNumber }) => phoneNumber,
      Status: ({ status }) => status,
      "Employment type": ({ employmentType }) => employmentType,
      "Position eligibility": ({ attributes }) => (attributes || []).join(", "),
    };

    // Returns an array of headers, and an array of rows
    const formattedData = {
      // Specific order that must match the order of the data
      headers: [...keys(staffHeaders)],
      data: staffItems?.map((staffItem) => {
        // Specific order that must match the order of the headers
        return [...map(staffHeaders, (headerFn) => headerFn(staffItem))];
      }),
    };

    // Create blob
    const content = arrayToCsv([formattedData.headers, ...formattedData.data]);
    const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const elementToClick = document.createElement("a");
    elementToClick.href = url;
    elementToClick.setAttribute("download", fileName);
    elementToClick.click();
  }, [staffItems, unit]);
};

function arrayToCsv(data: (string | number | undefined)[][]) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double quotes
          .map((v) => `"${v}"`) // quote it
          .join(","), // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}
