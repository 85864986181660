import "./ShiftEditor.scss";

import { useCallback, useEffect, useMemo, useState } from "react";

import { filter, keys, last, map, sortBy, uniqBy, values } from "lodash";

import { Box, Stack } from "@mui/material";

import {
  IScheduleShiftType,
  IStaffShift,
  ShiftTypeHelpers,
  StaffShift,
  useListScheduleShiftTypeQuery,
} from "@/api";
import { useCurrentUnit, useGroupBy, useMapBy } from "@/common/hooks";
import { localDayJs } from "@/common/packages/dayjs";
import { dateString } from "@/common/types";
import { add24Hours, timeAdd } from "@/common/utils/dates";

import { AppLoader } from "../AppLoader/AppLoader";
import { CustomButton } from "../TrackedComponents";

import { ShiftEditor } from "./ShiftEditor";
import { TShiftsEditorProps } from "./types";

/** Handle editing shifts for one staff on a given day
 *
 * 2 ways to use it:
 * - propagate changes instantly onCreate/onUpdate/onDelete
 * - stack them and save them all at once with saveChanges
 *   - autosave: true is used to propagate changes on first render, we use it
 *     when we want to autosave when we don't need to "split" a shift.
 */
export const ShiftsEditor = ({
  autoSave,
  staffShifts,
  staffShiftsWithEdits,
  saveChanges,
  staffDetails,
  unitsByScheduleId,
  newStaffShiftScaffold,
  cancelChanges,
  onCreate,
  onUpdate,
  onDelete,
  readonly = false,
  isLoading = false,
  readonlyUnit = true,
  readonlyIfShiftNotOnSelectedUnit = false,
}: TShiftsEditorProps) => {
  const scheduleIds = useMemo(() => keys(unitsByScheduleId), [unitsByScheduleId]);
  const { data: allAvailableShiftTypes } = useListScheduleShiftTypeQuery({
    scheduleIds,
  });
  const selectedUnitId = useCurrentUnit()?.id;
  const [autoSaved, setAutoSaved] = useState({ done: false, ready: false });
  const shiftTypesByScheduleId = useGroupBy(allAvailableShiftTypes, "scheduleId");

  // Initialize buffer. If staffShiftsWithEdits is not provided, also push them respectively to
  //  toCreate when the id is not present in the original staffShifts
  //  toUpdate when the id is present in the original staffShifts
  const originalShiftIds = useMapBy(staffShifts, "id");
  const [shiftsToUpdate, setShiftsToUpdate] = useState<IStaffShift[]>([]);
  const [shiftsToCreate, setShiftsToCreate] = useState<IStaffShift[]>([]);
  const [shiftsToDelete, setShiftsToDelete] = useState<IStaffShift[]>([]);
  const [shiftsBuffer, setShiftsBuffer] = useState<IStaffShift[]>([]);

  // Keep track of shifts validity
  const shiftTypeKeyValid = (staffShift: IStaffShift) =>
    shiftTypesByScheduleId[staffShift.scheduleId]?.find(
      ({ key }) => key === staffShift.shiftTypeKey,
    );
  const [shiftsValidity, setShiftsValidity] = useState<{ [key: IStaffShift["id"]]: boolean }>({});
  const preventSave =
    values(shiftsValidity).some((valid) => !valid) ||
    map(shiftsBuffer, shiftTypeKeyValid).some((key) => !key);

  useEffect(() => {
    setShiftsToUpdate(
      (staffShiftsWithEdits || []).filter(({ id }) => originalShiftIds.includes(id)),
    );
    setShiftsToCreate(
      (staffShiftsWithEdits || []).filter(({ id }) => !originalShiftIds.includes(id)),
    );
    setShiftsToDelete([]);
    setAutoSaved((previousValue) => ({ ...previousValue, ready: true }));
    setShiftsBuffer(
      uniqAndSortedShifts({
        staffShifts: [...(staffShiftsWithEdits || []), ...staffShifts],
        shiftTypes: allAvailableShiftTypes || [],
      }),
    );
  }, [staffShiftsWithEdits, staffShifts, originalShiftIds, allAvailableShiftTypes]);

  // Actions
  const createStaffShift = useCallback(() => {
    if (!newStaffShiftScaffold) return;

    const scheduleId = newStaffShiftScaffold.scheduleId;

    // Grab most appropriate shiftTypeKey
    const shiftTypeKey = (() => {
      // If provided in scaffold, use it
      if (newStaffShiftScaffold.shiftTypeKey) return newStaffShiftScaffold.shiftTypeKey;

      // from last edited shift or last shift
      const lastShift = last(shiftsBuffer);
      const lastShiftShiftType =
        lastShift &&
        shiftTypesByScheduleId[scheduleId]?.find(({ key }) => key === lastShift.shiftTypeKey)?.key;
      if (lastShiftShiftType) return lastShiftShiftType;

      // Else take schedule shift and take the first one, sorted by sortPosition
      return sortBy(shiftTypesByScheduleId[scheduleId], "sortPosition")[0]?.key;
    })();
    if (!shiftTypeKey) return;

    const now = new Date().toISOString() as dateString;

    const isWorkingAway = staffDetails?.homeUnitId !== unitsByScheduleId[scheduleId]?.id;
    const newStaffShift: IStaffShift | undefined = staffDetails
      ? {
          scheduleId,
          shiftTypeKey,
          date: newStaffShiftScaffold.date,
          scheduleType: newStaffShiftScaffold.scheduleType || StaffShift.EScheduleType.draft,

          id: window.crypto.randomUUID(),
          staffId: staffDetails.userId,

          customDuration: null,
          customStartTime: null,
          attributes: [],
          createdAt: now,
          updatedAt: now,
          deletedAt: null,
          status: isWorkingAway ? StaffShift.EStatus.floated : null,
          isWorkingAway: isWorkingAway,
        }
      : undefined;

    setShiftsToCreate((toCreate) => [...toCreate, ...filter([newStaffShift])]);
    setShiftsBuffer((buffer) => [...buffer, ...filter([newStaffShift])]);
    newStaffShift && onCreate?.(newStaffShift);
  }, [
    newStaffShiftScaffold,
    staffDetails,
    unitsByScheduleId,
    onCreate,
    shiftsBuffer,
    shiftTypesByScheduleId,
  ]);

  const deleteStaffShift = useCallback(
    (staffShift: IStaffShift) => {
      const staffShiftIds = map(staffShifts, "id");

      // If shift already exists, add it do delete, and remove it from delete if any
      if (staffShiftIds.includes(staffShift.id)) {
        setShiftsToDelete((toDelete) => [...toDelete, staffShift]);
        setShiftsToUpdate((toUpdate) => toUpdate.filter((shift) => shift.id !== staffShift.id));
      } else {
        // else clean it from toCreate
        setShiftsToCreate((toCreate) => toCreate.filter((shift) => shift.id !== staffShift.id));
      }

      setShiftsBuffer((buffer) => buffer.filter((shift) => shift.id !== staffShift.id));
      onDelete?.(staffShift);
    },
    [onDelete, staffShifts],
  );

  const updateStaffShift = useCallback(
    (staffShift: IStaffShift) => {
      const shiftsToUpdateIds = map(shiftsToUpdate, "id");
      const shiftsToCreateIds = map(shiftsToCreate, "id");

      // Update a "new" shift
      if (shiftsToCreateIds.includes(staffShift.id)) {
        setShiftsToCreate((toCreate) =>
          toCreate.map((shift) => (shift.id === staffShift.id ? staffShift : shift)),
        );
      }
      // Update a shift that was already updated
      else if (shiftsToUpdateIds.includes(staffShift.id)) {
        setShiftsToUpdate((toUpdate) =>
          toUpdate.map((shift) => (shift.id === staffShift.id ? staffShift : shift)),
        );
      }
      // Update shift that was not updated yet
      else {
        setShiftsToUpdate((toUpdate) => [...toUpdate, staffShift]);
      }

      setShiftsBuffer((buffer) =>
        buffer.map((shift) => (shift.id === staffShift.id ? staffShift : shift)),
      );
      onUpdate?.(staffShift);
    },
    [onUpdate, shiftsToCreate, shiftsToUpdate],
  );

  useEffect(() => {
    if (autoSave && saveChanges && !autoSaved.done && autoSaved.ready) {
      saveChanges({
        toCreate: shiftsToCreate,
        toDelete: shiftsToDelete,
        toUpdate: shiftsToUpdate,
      });
      setAutoSaved({ done: true, ready: true });
    }
  }, [autoSave, autoSaved, saveChanges, shiftsToCreate, shiftsToDelete, shiftsToUpdate]);

  const scheduleId = newStaffShiftScaffold?.scheduleId || shiftsBuffer[0]?.scheduleId;

  if (!scheduleId || autoSave || !staffDetails) return <AppLoader open />;

  return (
    <Stack className="m7-shifts-editor">
      <Stack className="shifts-to-edit">
        {uniqAndSortedShifts({
          staffShifts: shiftsBuffer,
          shiftTypes: allAvailableShiftTypes || [],
        }).map((staffShift) => {
          const unitOfShift = unitsByScheduleId[staffShift.scheduleId];
          const key = staffShift.id || window.crypto.randomUUID();
          if (!unitOfShift) return null;

          return (
            <ShiftEditor
              key={key}
              availableShiftTypes={shiftTypesByScheduleId[staffShift.scheduleId] || []}
              staffShift={staffShift}
              updateStaffShift={updateStaffShift}
              deleteStaffShift={deleteStaffShift}
              unitOfShift={unitOfShift}
              readonly={
                readonly || (readonlyIfShiftNotOnSelectedUnit && unitOfShift.id !== selectedUnitId)
              }
              staffDetails={staffDetails}
              readonlyUnit={readonlyUnit}
              setShiftsValidity={setShiftsValidity}
            />
          );
        })}
      </Stack>
      <Box flexGrow={1} />
      {!readonly && (
        <Stack className="actions">
          <CustomButton
            fullWidth
            disabled={isLoading}
            label="+ Add Time"
            onClick={createStaffShift}
            variant="outlined"
            color="primary"
            sx={{ mb: "10px" }}
          />
          <Stack direction="row" gap={"10px"} width={"100%"}>
            {saveChanges && (
              <CustomButton
                fullWidth
                disabled={isLoading || preventSave}
                label="Save"
                onClick={() =>
                  saveChanges({
                    toCreate: shiftsToCreate,
                    toDelete: shiftsToDelete,
                    toUpdate: shiftsToUpdate,
                  })
                }
                variant="contained"
                color="primary"
              />
            )}
            {cancelChanges && (
              <CustomButton
                fullWidth
                disabled={isLoading}
                label="Cancel"
                onClick={cancelChanges}
                variant="contained"
                color="primary"
              />
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

// Sort shift by startTime, from custom params or shiftType
const uniqAndSortedShifts = ({
  staffShifts,
  shiftTypes,
}: {
  staffShifts: IStaffShift[];
  shiftTypes: IScheduleShiftType[];
}) => {
  const indexedShiftTypes = ShiftTypeHelpers.byScheduleIdByShiftTypeKey(shiftTypes || []);

  return sortBy(uniqBy(staffShifts, "id"), (shift) => {
    const shiftType = indexedShiftTypes[shift.scheduleId]?.[shift.shiftTypeKey];
    const startTime = shift.customStartTime || shiftType?.startTime;
    const duration = shift.customDuration || shiftType?.durationSeconds;
    if (!startTime || !duration) return new Date(0);

    let endTime = timeAdd(startTime, duration);
    // Shouldn't this be add24Hours(endTime) instead of add24Hours(startTime)? @ngouy?
    if (endTime < startTime) endTime = add24Hours(startTime);
    let dateJs = localDayJs(startTime, "HH:MM:SS");

    // Start on next day if shift ends after midnight or starts between midnight and 7am
    if (startTime > endTime || startTime < "07:00:00") dateJs = dateJs.add(1, "day");

    return dateJs.toDate();
  });
};
