import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { EUnitPermissionAreas } from "@m7-health/shared-utils";
import { filter, intersection } from "lodash";

import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, Typography } from "@mui/material";

import MeatballsMenu from "~/common/components/MeatballsMenu";
import { SendMassSms } from "~/common/components/SendMassSms/SendMassSms";
import CustomTabs from "~/common/components/Tabs";
import CustomButton from "~/common/components/TrackedComponents/Button";
import { useCurrentUnitId } from "~/common/hooks/useCurrentUnitId";
import { useIsAdmin } from "~/common/hooks/useIsAdmin";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { setSelectedUnit, setUnitModal } from "~/common/store";
import { darkGray } from "~/common/theming/colors";
import {
  openModal,
  setIsAddUserModalOpen,
  setIsBulkUsersUploadModalOpen,
  setIsEditAttributeEligibilityModalOpen,
  setIsEditFloatEligibilityModalOpen,
} from "~/features/Roster/store";
import { useUnitQuery } from "~/routes/queries";

import { schedulePermissions } from "#/features/SchedulerGrid/constants";
import { useAppFlags, useCheckUserPermission } from "@/common/hooks";
import { useDownloadRoster } from "@/common/hooks/useDownloadRoster";
import { useDownloadSubmissions } from "@/common/hooks/useDownloadSubmissions";

import { RosterModals } from "../modals";
import { ERosterPageTabs } from "../types";

import { useSelectedRosterTab } from "./hooks/useSelectedRosterTab";
import StaffRosterTable from "./StaffRosterTable";

export enum ERosterUserOptions {
  home = "home",
  other = "other",
  all = "all",
}

const tabs = [
  { label: "Home Unit Users", value: ERosterPageTabs.homeUnitUsers },
  { label: "Floats from Other Units", value: ERosterPageTabs.otherUnitUsers },
  { label: "Suspended Users", value: ERosterPageTabs.suspendedUsers },
];

const StaffRoster = () => {
  const dispatch = useAppDispatch();
  const isAdmin = useIsAdmin();
  const currentUnitId = useCurrentUnitId();
  const { preferenceRequirementsV2 } = useAppFlags();

  const { data: units, isLoading: isLoadingUnits } = useUnitQuery();
  const downloadSubmissionsCsv = useDownloadSubmissions(currentUnitId, !isAdmin);
  const downloadRosterCsv = useDownloadRoster();

  const { unitId } = useParams();
  const navigate = useNavigate();
  const { selectedRosterTab } = useSelectedRosterTab();

  const userRoles = useAppSelector((state) => state.user.userData.roles);
  const selectedUnit = useAppSelector((state) => state.common.currentUnit);
  const canManage = useCheckUserPermission("manage", EUnitPermissionAreas.staffRoster);

  useEffect(() => {
    if (!unitId && selectedUnit) {
      navigate(`/roster/staff/${selectedUnit.id}/${ERosterPageTabs.homeUnitUsers}`);
    } else if (unitId && !selectedRosterTab) {
      navigate(`/roster/staff/${unitId}/${ERosterPageTabs.homeUnitUsers}`);
    } else if (unitId && (!selectedUnit || unitId !== selectedUnit?.id)) {
      const unit = units?.find((item) => item.id === unitId);
      if (unit) {
        dispatch(setSelectedUnit(unit));
      }
    }
  }, [dispatch, navigate, selectedRosterTab, selectedUnit, selectedUnit?.id, unitId, units]);

  const handleEditUnitModal = () => {
    dispatch(setUnitModal("edit"));
  };

  const handleBulkUsersUploadModal = () => {
    dispatch(setIsBulkUsersUploadModalOpen(true));
  };

  const handleEditFloatEligibilityModal = () => {
    dispatch(setIsEditFloatEligibilityModalOpen(true));
  };

  const handleEditAttributeEligibilityModal = () => {
    dispatch(setIsEditAttributeEligibilityModalOpen(true));
  };

  const handleUpdateRuleSetsModal = () => {
    dispatch(openModal("updateRuleSetsModal"));
  };

  const hasUnitEditPermission =
    intersection(userRoles, schedulePermissions.editSchedule)?.length > 0;

  const menuItems = filter([
    hasUnitEditPermission &&
      canManage && {
        label: "Edit Unit",
        onClick: handleEditUnitModal,
      },
    hasUnitEditPermission &&
      canManage && {
        label: "Bulk Upload Users",
        onClick: handleBulkUsersUploadModal,
      },
    {
      label: "⬇ Survey Responses (CSV)",
      onClick: () => downloadSubmissionsCsv(),
    },
    {
      label: "⬇ Download Roster (CSV)",
      onClick: () => downloadRosterCsv(),
    },
    {
      label: "Update Float Eligibility",
      onClick: handleEditFloatEligibilityModal,
    },
    {
      label: "Update Position Eligibility",
      onClick: handleEditAttributeEligibilityModal,
    },
    ...(preferenceRequirementsV2
      ? [{ label: "Update Requirements", onClick: handleUpdateRuleSetsModal }]
      : []),
  ]);

  const noUnitContent = (
    <Grid flexDirection="column" alignItems="center" container mt="15%">
      <Typography variant="h5" component="p">
        Welcome!
      </Typography>
      <Typography mt={2} variant="body1" fontWeight={300} color={darkGray}>
        Create your first unit to begin.
      </Typography>
      <Box mt={5}>
        <CustomButton label="Create unit" onClick={() => dispatch(setUnitModal("create"))} />
      </Box>
    </Grid>
  );

  const getRosterUserOptionPerTab = (rosterTabOption: ERosterPageTabs) => {
    switch (rosterTabOption) {
      case ERosterPageTabs.homeUnitUsers:
        return ERosterUserOptions.home;
      case ERosterPageTabs.suspendedUsers:
        return ERosterUserOptions.all;
      case ERosterPageTabs.otherUnitUsers:
        return ERosterUserOptions.other;
      default:
        return ERosterUserOptions.home;
    }
  };

  return (
    <>
      <Box
        pt={5}
        display="flex"
        flexDirection="column"
        maxHeight="100%"
        width="100%"
        maxWidth="100%"
        boxSizing="border-box"
      >
        {selectedUnit && (
          <Grid container pl={3} pr={3} alignItems="center">
            <Grid item container xs={8} width="auto" alignItems="center" gap={3}>
              <Box>
                <Typography variant="h5">{selectedUnit.name} Staff Roster</Typography>
                {selectedUnit.facility && (
                  <Typography variant="body1" fontWeight={300} color={darkGray}>
                    Facility: {selectedUnit.facility.name}
                  </Typography>
                )}
              </Box>
              <CustomTabs<ERosterPageTabs>
                onChange={(newTab) => navigate(`/roster/staff/${selectedUnit.id}/${newTab}`)}
                pillTabs
                tabs={tabs}
                value={selectedRosterTab}
                width="auto"
              />
            </Grid>
            <Grid item container gap={1} xs={4} justifyContent="end">
              {canManage && (
                <>
                  <CustomButton
                    label="Add User"
                    onClick={() => dispatch(setIsAddUserModalOpen(true))}
                    startIcon={<AddIcon />}
                  />
                  <SendMassSms.Button />
                </>
              )}
              {!!menuItems.length && <MeatballsMenu items={menuItems} />}
            </Grid>
          </Grid>
        )}
        <>
          {!isLoadingUnits && units?.length === 0 ? (
            noUnitContent
          ) : (
            <StaffRosterTable
              // use home unit users if selected tab is home unit users or suspended users
              userOption={getRosterUserOptionPerTab(selectedRosterTab)}
              isSuspended={selectedRosterTab === ERosterPageTabs.suspendedUsers}
              key={selectedRosterTab}
            />
          )}
        </>
      </Box>
      <RosterModals />
    </>
  );
};

export default StaffRoster;
