import { User as UserSharedUtils, ISODateString } from "@m7-health/shared-utils";

import { ICustomSelectItem } from "~/common/components/TrackedComponents/Select/types";
import { axiosInstance } from "~/common/packages/httpClient";
import { ITable } from "~/common/types";
import { getFullName } from "~/common/utils/user";
import { IUser } from "~/features/User/types";

import { User } from "@/api";

import { ERosterUserOptions } from "../StaffRoster/StaffRoster";
import {
  EStaffStatus,
  IEditUser,
  IKioskDto,
  IScheduler,
  ISchedulerDto,
  IStaff,
  IStaffWithDetailsDto,
  TCreateSchedulerDto,
  TCreateStaffDto,
} from "../types";

const USERS_ENDPOINT = "/users";

export interface IGetRoster {
  skip?: number;
  take?: number;
  unitId?: string;
  facilityId?: string;
  suspended?: Array<boolean>;
  userOption?: ERosterUserOptions;
}

export const getRosterApi = async ({
  skip = 0,
  take = 10,
  unitId,
  suspended,
  userOption = ERosterUserOptions.all,
}: IGetRoster) => {
  const endpoint = `/units/${unitId}/roster`;

  const { data } = await axiosInstance.get<
    ITable<IStaffWithDetailsDto | ISchedulerDto | IKioskDto>
  >(endpoint, {
    params: { skip, take, suspended, userOption },
  });

  return data;
};

export const getSchedulerRosterApi = async ({
  skip = 0,
  take = 10,
  facilityId,
  suspended,
}: IGetRoster) => {
  const endpoint = `/units/${facilityId}/roster/schedulers`;

  const { data } = await axiosInstance.get<ITable<IStaffWithDetailsDto | ISchedulerDto>>(endpoint, {
    params: { skip, take, suspended },
  });

  return data;
};

// TODO: extract to API directory
export const sendMassSmsApi = async (body: {
  userIds: IUser["id"][];
  messageContent: string;
}): Promise<{ success: boolean; errors: unknown[] }> => {
  const response = await axiosInstance.post<{ success: boolean; errors: unknown[] }>(
    "/notification/mass-send-sms",
    body,
  );
  return response.data;
};

export const addUserApi = async (body: IStaff | IScheduler) => {
  const { role, unitAssignment, ...rest } = body;

  const convertedBody: TCreateStaffDto | TCreateSchedulerDto =
    role === User.ERole.staff
      ? ({ ...rest, role, unitIds: unitAssignment } as TCreateStaffDto)
      : { ...rest, role, unitIds: unitAssignment };

  const { data } = await axiosInstance.post<{ id: string }>(USERS_ENDPOINT, convertedBody);

  return data;
};

export const editUserApi = async (body: { id: string }) => {
  const { id, ...rest } = body;

  const endpoint = `/users/${id}`;

  await axiosInstance.patch(endpoint, { ...rest });
};

export const getUserApi = async (id: string) => {
  const endpoint = `/users/${id}`;

  const { data } = await axiosInstance.get<IEditUser>(endpoint);

  return data;
};

interface IGetSchedulers {
  data: ISchedulerDto[];
}

export const getUnassignedSchedulers = async (unitId?: string): Promise<ICustomSelectItem[]> => {
  if (!unitId) {
    return [];
  }
  const endpoint = `/units/${unitId}/not-assigned-schedulers`;

  const {
    data: { data },
  } = await axiosInstance.get<IGetSchedulers>(endpoint);

  return data.map(
    ({ id, firstName, lastName }) =>
      ({
        label: getFullName(firstName, lastName, true),
        value: id,
      }) as ICustomSelectItem,
  );
};

export const assignSchedulerToUnit = async ({
  schedulerId,
  unitId,
}: {
  schedulerId: string;
  unitId?: string;
}) => {
  if (!unitId) {
    return;
  }
  const endpoint = `/units/${unitId}/assign-schedulers`;
  const body = { schedulerIds: [schedulerId] };

  await axiosInstance.post(endpoint, body);
};

export const editStaffNote = async ({ id, note }: { id?: string; note: string }) => {
  const endpoint = `${USERS_ENDPOINT}/${id}`;
  const body = { staffDetails: { note } };
  await axiosInstance.patch(endpoint, body);
};

export const changeStaffStatus = async ({
  staffId,
  status,
  postDateStatusUpdateType,
  postDateStatusUpdateDate,
  reasonForSuspension,
  noteForSuspension,
}: {
  staffId: string;
  status: EStaffStatus;
  postDateStatusUpdateDate?: ISODateString | null;
  postDateStatusUpdateType?: "inactive" | "suspended" | null;
  reasonForSuspension?: UserSharedUtils.EReasonForSuspension;
  noteForSuspension?: string;
}) => {
  if (!staffId) {
    return;
  }
  const endpoint = `${USERS_ENDPOINT}/${staffId}`;
  const body = {
    reasonForSuspension,
    noteForSuspension,
    staffDetails: { status, postDateStatusUpdateType, postDateStatusUpdateDate },
  };

  await axiosInstance.patch(endpoint, body);
};

export const submitReasonAndNoteForSuspension = async ({
  staffId,
  reasonForSuspension,
  noteForSuspension,
}: {
  staffId: string;
  reasonForSuspension?: UserSharedUtils.EReasonForSuspension;
  noteForSuspension?: string;
}) => {
  if (!staffId) {
    return;
  }
  const endpoint = `${USERS_ENDPOINT}/${staffId}`;
  const body = {
    reasonForSuspension,
    noteForSuspension,
  };

  await axiosInstance.patch(endpoint, body);
};

export const postBulkUserUpload = async ({ file, unitId }: { file: File; unitId?: string }) => {
  if (!unitId) {
    return;
  }

  const endpoint = `/users/bulk-import`;
  const body = { unitId, file };

  await axiosInstance.postForm(endpoint, body);
};

export const suspendAccount = async ({ id }: { id: string }) => {
  const endpoint = `/users/${id}/suspend-account`;

  await axiosInstance.put(endpoint);
};

export const restoreAccount = async ({ id }: { id: string }) => {
  const endpoint = `/users/${id}/restore-account`;

  await axiosInstance.put(endpoint);
};
