import { StaffDetails } from "@/api";

const employmentOrder: { [key in StaffDetails.EEmploymentType]: number } = {
  [StaffDetails.EEmploymentType.fullTime]: 1,
  [StaffDetails.EEmploymentType.partTime]: 2,
  [StaffDetails.EEmploymentType.travelStaff]: 3,
  [StaffDetails.EEmploymentType.perDiem]: 4,
  [StaffDetails.EEmploymentType.inHouseContract]: 5,
  [StaffDetails.EEmploymentType.flex]: 6,
  [StaffDetails.EEmploymentType.contract]: 7,
};

/**
 * Compare function to sort staff by primary shift type, employment type, and last name
 * @param sortStaffByPrimaryShiftTypeFlag - flag to sort staff by primary shift type
 * @param a - first staff to compare
 * @param b - second staff to compare
 * @param selectedUnitId - id of the selected unit
 * @param availableShiftTypes - available shift types
 */
export const compareStaffOrder = (
  sortStaffByPrimaryShiftTypeFlag: boolean | undefined,
  a: StaffDetails.DTO,
  b: StaffDetails.DTO,
  selectedUnitId: string,
  availableShiftTypes: Record<string, { sortPosition?: number | null | undefined }>,
) => {
  // Handle floated sorting first
  const isASelected = a.homeUnitId === selectedUnitId;
  const isBSelected = b.homeUnitId === selectedUnitId;

  if (isASelected && !isBSelected) {
    return -1; // a should come first if it's the selected unit
  }
  if (!isASelected && isBSelected) {
    return 1; // b should come first if it's the selected unit
  }

  // Handle primary shift type sorting if configured
  if (sortStaffByPrimaryShiftTypeFlag) {
    const primaryShiftTypeA = a.shiftType;
    const primaryShiftTypeB = b.shiftType;

    // if the primary shift type is different, sort by the sortPosition of the shift type
    if (primaryShiftTypeA !== primaryShiftTypeB) {
      const sortedPositionA =
        (primaryShiftTypeA && availableShiftTypes?.[primaryShiftTypeA]?.sortPosition) || null;
      const sortedPositionB =
        (primaryShiftTypeB && availableShiftTypes?.[primaryShiftTypeB]?.sortPosition) || null;
      // if both shift types have a sortPosition, sort by that, else sort by the shift type name
      return sortedPositionA && sortedPositionB
        ? sortedPositionA - sortedPositionB
        : sortedPositionA && !sortedPositionB
          ? -1
          : 1;
    }
  }

  // Handle employmentType sorting
  const employmentA = a.employmentType;
  const employmentB = b.employmentType;

  if (employmentOrder[employmentA] !== employmentOrder[employmentB]) {
    return employmentOrder[employmentA] - employmentOrder[employmentB];
  }

  // If employmentType is equal, sort by lastName then firstName
  return (
    a.user.lastName.localeCompare(b.user.lastName) ||
    a.user.firstName.localeCompare(b.user.firstName)
  );
};
