import { useEffect } from "react";

import { Timezone, Uuid } from "@m7-health/shared-utils";

import { IFacility, User } from "@/api";

import { localDayJs } from "../packages/dayjs";
import { setCurrentContext } from "../store";

import { useCurrentFacility, useCurrentFacilityId } from "./useCurrentFacilityId";
import { useCurrentRole } from "./useCurrentRole";
import { useCurrentUnit, useCurrentUnitId } from "./useCurrentUnitId";
import { useAppDispatch, useAppSelector } from "./useRedux";
import { useCurrentUser } from "./useUser";

export const useM7Context = () => {
  return useAppSelector((state) => state.common.m7Context);
};
export type TM7Context = ReturnType<typeof useM7Context>;

export const useM7SimpleContext = () =>
  useAppSelector(({ common: { m7Context } }) => ({
    currentUserId: m7Context.currentUserId,
    currentUserEmail: m7Context.currentUser?.email,
    currentUnitName: m7Context.currentUnit?.name,
    currentFacilityName: m7Context.currentFacility?.name,
    currentRole: m7Context.currentRole,
    currentUnitId: m7Context.currentUnitId,
    currentFacilityId: m7Context.currentFacilityId,

    // TODO: decide in current prefix vs not
    timezone: (m7Context.currentUnit?.timezone ||
      m7Context.currentFacility?.timezone ||
      localDayJs.tz.guess()) as Timezone,
    currentTimezone: (m7Context.currentUnit?.timezone ||
      m7Context.currentFacility?.timezone ||
      localDayJs.tz.guess()) as Timezone,
  }));
export type TM7SimpleContext = ReturnType<typeof useM7SimpleContext>;

export const useSetM7Context = () => {
  const dispatch = useAppDispatch();

  const currentUser = useCurrentUser();
  const currentUnit = useCurrentUnit();
  const currentFacility = useCurrentFacility() as IFacility;
  const currentRole = useCurrentRole() as User.ERole;

  // Using dedicated hooks set once http helper is mounted with the new unit/facilityId
  const currentUnitId = useCurrentUnitId();
  const currentFacilityId = useCurrentFacilityId();

  useEffect(() => {
    dispatch(
      setCurrentContext({
        currentUser,
        currentUnit,
        currentFacility,
        currentRole,
        currentUnitId: currentUnitId,
        currentFacilityId: currentFacilityId as Uuid | undefined,
        currentUserId: currentUser?.id,
        currentTimezone: (currentUnit?.timezone ||
          currentFacility?.timezone ||
          localDayJs.tz.guess()) as Timezone,
      }),
    );
  }, [
    currentUser,
    currentUnit,
    currentFacility,
    currentRole,
    currentUnitId,
    currentFacilityId,
    dispatch,
  ]);
};
