import { useCallback, useMemo } from "react";

// eslint-disable-next-line deprecate/import
import { orderBy } from "lodash";

import { Delete, Edit, Person } from "@mui/icons-material";
import { Box, Chip, List, ListItem, Stack, Typography } from "@mui/material";

import {
  IPreferenceRequirementRuleSet,
  useDeletePreferenceRequirementRuleSetMutation,
  useInvalidateQuery,
  useListPreferenceRequirementRuleSetsQuery,
} from "@/api";
import { CustomButton, CustomModal, RuleSetLabel } from "@/common/components";
import { NOT_EXISTING_UUID } from "@/common/constants";
import { useAppDispatch, useCurrentUnitId, useErrors, useToast } from "@/common/hooks";

import { closeModal, openModal, setRuleSetToUpdate, setRuleSetToUpdateUsers } from "../../store";

export const UpdateRuleSetsModal = () => {
  const currentUnitId = useCurrentUnitId();
  const dispatch = useAppDispatch();
  const { handleErrors } = useErrors();
  const { showSuccess } = useToast();
  const invalidateQuery = useInvalidateQuery();

  const { data: ruleSets } = useListPreferenceRequirementRuleSetsQuery(
    { unitIds: [currentUnitId || NOT_EXISTING_UUID] },
    { skip: !currentUnitId },
  );
  const { mutateAsync: deleteRuleSet } = useDeletePreferenceRequirementRuleSetMutation({});
  const orderedRuleSets = useMemo(() => orderBy(ruleSets, "createdAt"), [ruleSets]);

  // Actions
  const closeModalAction = useCallback(() => {
    dispatch(closeModal("updateRuleSetsModal"));
  }, [dispatch]);
  const OpenUpdateRuleSetModal = useCallback(
    (ruleSet: IPreferenceRequirementRuleSet | null) => {
      return () => {
        dispatch(setRuleSetToUpdate(ruleSet));
        dispatch(openModal("updateRuleSetModal"));
        dispatch(closeModal("updateRuleSetsModal"));
      };
    },
    [dispatch],
  );
  const deleteRuleSetAction = useCallback(
    (ruleSetId: IPreferenceRequirementRuleSet["id"]) => {
      void (async () => {
        try {
          await deleteRuleSet({ id: ruleSetId });
          showSuccess("Requirements deleted successfully");
          invalidateQuery(useListPreferenceRequirementRuleSetsQuery);
        } catch (error) {
          handleErrors(error);
        }
      })();
    },
    [deleteRuleSet, handleErrors, invalidateQuery, showSuccess],
  );

  const modalContent = useMemo(() => {
    return (
      <List>
        {orderedRuleSets.map((ruleSet) => (
          <ListItem key={ruleSet.id} sx={{ p: 0 }}>
            <RuleSetLabel ruleSet={ruleSet} />
            <Typography children={ruleSet.name} width={"200px"} noWrap />
            <Chip
              deleteIcon={<Edit />}
              onDelete={() => {
                dispatch(openModal("updateRuleSetUsersModal"));
                dispatch(closeModal("updateRuleSetsModal"));
                dispatch(setRuleSetToUpdateUsers(ruleSet));
              }}
              sx={{
                width: "80px",
                borderRadius: "5px",
                svg: { fontSize: "18px !important" },
              }}
              label={
                <>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography children={ruleSet.rosterRuleSets.length} />
                    <Person />
                  </Stack>
                </>
              }
            />
            <Box sx={{ width: "100px" }} />
            <CustomButton
              onClick={() => {
                dispatch(openModal("updateRuleSetModal"));
                dispatch(setRuleSetToUpdate(ruleSet));
              }}
              iconOnly
              startIcon={<Edit />}
              label={"Edit Requirement"}
            />
            <CustomButton
              trackingLabel="delete-rule-set"
              onClick={() => deleteRuleSetAction(ruleSet.id)}
              iconOnly
              startIcon={<Delete />}
              confirmation="Are you sure you want to delete this rule set? It will also remove it from all users."
              label="Delete Requirement"
            />
          </ListItem>
        ))}
      </List>
    );
  }, [deleteRuleSetAction, dispatch, orderedRuleSets]);

  return (
    <CustomModal
      isOpen={true}
      modalHeaderText="Manage Requirements"
      primaryBtnText="Create Requirement Set"
      secondaryBtnText="Close"
      modalContent={modalContent}
      onClose={closeModalAction}
      onSecondaryBtnClick={closeModalAction}
      onSubmit={OpenUpdateRuleSetModal(null)}
    />
  );
};
