import { memo, useMemo } from "react";

import { YyyyMmDd } from "@m7-health/shared-utils";
import { round } from "lodash";

import { Box, Grid, Typography } from "@mui/material";

import { Schedule, StaffCategory } from "~/api";

import { returnStaffedColorForTarget } from "#/features/HouseView/hooks/useStaffingTabs";
import { EHVTimeRange } from "#/features/HouseView/store/pageFiltersActions";
import { useAppConfigQuery } from "#/features/User/queries";
import { useAppSelector, useDeepMemo, useSelectedCurrentFacilityId } from "@/common/hooks";
import { Dayjs } from "@/common/packages/dayjs";
import { black, lightGray, lines } from "@/common/theming";
import { pxToRem } from "@/common/utils/pxToRem";

import { TableStyleContainer } from "../style";
import { ITablesData } from "../Table";

import { HeaderCellContainer, ShiftTypeWrapper } from "./HeaderCell.styled";

const rangeToTimeMapping: Record<
  Exclude<EHVTimeRange, EHVTimeRange.all>,
  { startTime: string; endTime: string }
> = {
  [EHVTimeRange.day7A7P]: { startTime: "07:00:00", endTime: "19:00:00" },
  [EHVTimeRange.night7P7A]: { startTime: "19:00:00", endTime: "07:00:00" },
};

export const TotalCountHeader = memo(
  ({
    datesRows,
    formattedDateRows,
    schedules,
    tablesData,
  }: {
    datesRows: Dayjs[];
    formattedDateRows: YyyyMmDd[];
    schedules: Schedule.DTO[];
    tablesData: ITablesData;
  }) => {
    const { timeRange, unitIdsFilter, selectedShowTargetLevels, selectedStaffCategory } =
      useAppSelector((state) => ({
        timeRange: state.houseView.pageFilters.timeRange,
        unitIdsFilter: state.houseView.pageFilters.unitIds,
        selectedShowTargetLevels: state.houseView.pageFilters.showTargetLevels,
        selectedStaffCategory: state.houseView.pageFilters.selectedStaffCategory,
      }));

    const selectedFacilityId = useSelectedCurrentFacilityId();

    const { data: appConfig } = useAppConfigQuery();

    const facilityConfig = useMemo(() => {
      return appConfig?.facilities.find((facility) => facility.id === selectedFacilityId)
        ?.configuration;
    }, [appConfig?.facilities, selectedFacilityId]);

    const timeRangesToShow = useDeepMemo(() => {
      return timeRange === EHVTimeRange.all
        ? [EHVTimeRange.day7A7P, EHVTimeRange.night7P7A]
        : [timeRange];
    }, [timeRange]);

    const timeRangeTargetLevelsForStaffCategory = useMemo(() => {
      const targetLevelsForStaffCategory =
        facilityConfig?.data?.facilityTimeTargetLevelsByStaff?.[
          selectedStaffCategory || StaffCategory.EKey.nurse
        ];
      return timeRangesToShow.map((range) => {
        const timeRangeAsTimeObject =
          rangeToTimeMapping[range as Exclude<EHVTimeRange, EHVTimeRange.all>];
        return targetLevelsForStaffCategory?.find(
          (targetLevel) =>
            targetLevel.startTime === timeRangeAsTimeObject.startTime &&
            targetLevel.endTime === timeRangeAsTimeObject.endTime,
        );
      });
    }, [
      facilityConfig?.data?.facilityTimeTargetLevelsByStaff,
      selectedStaffCategory,
      timeRangesToShow,
    ]);

    return (
      <Box
        mt={3}
        width="97vw"
        marginBottom="60px"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        sx={{ position: "sticky", top: 0, zIndex: 1000 }}
      >
        <TableStyleContainer>
          <table
            className={`scheduler-table mini-view v2`}
            style={{ tableLayout: "fixed", borderCollapse: "separate", borderSpacing: 0 }}
          >
            <tbody>
              <tr>
                {/* Corner cell */}
                <th className="sticky-col sticky-corner">
                  <Grid container flexDirection="column">
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      sx={{
                        minWidth: "300px",
                        height: "52px",
                      }}
                      item
                      justifyContent="flex-end"
                    >
                      <Typography variant="small" fontSize={pxToRem(21)} fontWeight={600} mr={2}>
                        TOTAL STAFF
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      flexDirection="column"
                      flexWrap="nowrap"
                      sx={{
                        borderTop: `1px solid ${lines}`,
                      }}
                    >
                      {timeRangesToShow.map((aRange) => (
                        <Grid
                          sx={{
                            height: `${26}px`,
                            background: lightGray,
                            borderTop: `${1}px solid ${lines}`,
                            borderBottom: `0px solid ${lines}`,
                          }}
                          container
                          alignItems="center"
                        >
                          <Typography pl={2} fontSize="0.75rem" fontWeight="400">
                            {aRange === EHVTimeRange.day7A7P ? "Day" : "Night"}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </th>

                {/* Add a column for each date */}
                {datesRows.map((cellDate, index) => {
                  const formattedDate = formattedDateRows[index] || cellDate.format("YYYY-MM-DD");

                  const classNames = [];
                  if (cellDate.day() === 6) classNames.push("sunday");
                  classNames.push(formattedDate);

                  return (
                    <th
                      style={{
                        padding: 0,
                        borderTop: 0,
                        position: "sticky",
                        backgroundColor: "white",
                        zIndex: 4,
                      }}
                      key={formattedDate}
                      className={classNames.join(" ")}
                    >
                      <div>
                        <HeaderCellContainer
                          flexDirection="column"
                          alignItems="flex-start"
                          sx={{
                            height: "52px",

                            opacity: 1,
                          }}
                        >
                          <Box
                            sx={{
                              paddingTop: "3px",
                              borderTop: "1px solid transparent",
                              display: "flex",
                              fontWeight: 500,
                              lineHeight: "24px",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              align="center"
                              variant="small"
                              display="block"
                              sx={{
                                marginBottom: "-7px",
                                marginTop: "-7px",
                                fontSize: "13px",
                                color: "black",
                              }}
                            >
                              {/* use no-break-space character \u00A0 to make sure display="block" is still applied */}
                              {cellDate.format("DD") === "01" ? cellDate.format("MMM") : "\u00A0"}
                            </Typography>
                            <Typography
                              align="center"
                              variant="small"
                              display="block"
                              sx={{
                                marginBottom: "-7px",
                                color: "black",
                              }}
                            >
                              {cellDate.format("DD")}
                            </Typography>
                            <Typography
                              align="center"
                              variant="small"
                              display="block"
                              sx={{
                                color: "black",
                              }}
                            >
                              {cellDate.format("dd")}
                            </Typography>
                          </Box>
                          <Grid>
                            {timeRangesToShow.map((aRange, idx) => {
                              // find all schedules with this daykey that is between startDate and endDate
                              const dayKey = cellDate.format("YYYY-MM-DD");
                              const filteredSchedules = schedules.filter(
                                ({ startDay, endDay, unitId }) =>
                                  startDay <= dayKey &&
                                  endDay >= dayKey &&
                                  (unitIdsFilter.length === 0 || unitIdsFilter.includes(unitId)),
                              );

                              //add up the total number of shifts across all schedules for this day and time range
                              const total = filteredSchedules.reduce((acc, schedule) => {
                                const shiftsByDayByRange = tablesData?.[schedule?.id || ""];
                                return (
                                  acc +
                                  (shiftsByDayByRange?.[dayKey]?.[
                                    aRange as Exclude<EHVTimeRange, EHVTimeRange.all>
                                  ] || 0)
                                );
                              }, 0);
                              const count = round(total, 1);
                              const minCount = timeRangeTargetLevelsForStaffCategory?.[idx]?.min;
                              const backgroundColor = minCount
                                ? returnStaffedColorForTarget(count, minCount)
                                : "transparent";

                              return (
                                <Grid
                                  key={`${dayKey}-${aRange}`}
                                  container
                                  alignItems="center"
                                  justifyContent="space-around"
                                  sx={{
                                    borderTop: `1px solid ${lines}`,
                                    height: "27px",
                                    background: backgroundColor,
                                    borderBottom: `0px solid ${lines}`,
                                  }}
                                >
                                  <ShiftTypeWrapper>
                                    <Typography
                                      sx={{
                                        fontSize: "0.8rem",
                                        color: black,
                                        px: 1,
                                      }}
                                    >
                                      {count}
                                      {selectedShowTargetLevels && minCount && `/${minCount}`}
                                    </Typography>
                                  </ShiftTypeWrapper>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </HeaderCellContainer>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </TableStyleContainer>
      </Box>
    );
  },
);
