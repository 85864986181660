import { useEffect, useMemo, useState } from "react";

import { orderBy } from "lodash";

import { Box, Typography } from "@mui/material";

import { useBulkSaveStaffDetails } from "~/api/staffDetails/mutations";
import CustomModal from "~/common/components/Modal";
import { useAppDispatch, useAppSelector } from "~/common/hooks/useRedux";
import { useToast } from "~/common/hooks/useToast";

import { useAppConfigQuery } from "#/features/User/queries";
import { Attribute, TBulkSaveStaffDetails, User } from "@/api";
import { CustomButton, CustomSelect } from "@/common/components";
import { ICustomSelectItem } from "@/common/components/TrackedComponents/Select/types";
import {
  useCurrentFacilityId,
  useCurrentSelectedUnitId,
  useFilterBy,
  useMap,
} from "@/common/hooks";
import { Uuid } from "@/common/types";

import { useRosterQueryAll } from "../queries";
import { ERosterUserOptions } from "../StaffRoster/StaffRoster";
import { setIsEditAttributeEligibilityModalOpen } from "../store";
import { IStaffWithDetailsDto } from "../types";

export const EditAttributeEligibilityModal = () => {
  // High- level state
  const dispatch = useAppDispatch();
  const { showSuccess, showError } = useToast();

  // Local state
  const isEditAttributeEligibilityOpen = useAppSelector(
    (state) => state.roster.editAttributeEligibilityModal.isOpen,
  );
  const handleSecondaryBtnClick = () => {
    dispatch(setIsEditAttributeEligibilityModalOpen(false));
  };

  // Unit Data
  const unitsDTOs = useAppConfigQuery().data?.accessibleUnits;
  const currentFacilityId = useCurrentFacilityId();
  const currentUnitId = useCurrentSelectedUnitId();

  // Filter units by current facility
  const facilityUnits = useFilterBy(unitsDTOs, (unit) => unit.facilityId === currentFacilityId, [
    currentFacilityId,
  ]);
  // All options for custom select
  const unitsCustomSelect: ICustomSelectItem<Uuid>[] = useMap(facilityUnits, (unit) => {
    return { ...unit, value: unit.id, label: unit.name, item: unit.id } as ICustomSelectItem<Uuid>;
  });
  // Selected Value
  const [selectedUnit, setSelectedUnit] = useState<Uuid | undefined>(undefined);

  // Attributes / Positions Data
  const unitDTO = facilityUnits?.find((unit) => unit.id === selectedUnit);
  const attributesCustomSelect: ICustomSelectItem<string>[] = useMap(
    unitDTO?.attributes || [],
    (attribute) => {
      return {
        ...attribute,
        value: attribute.key,
        label: attribute.name,
        item: attribute.key,
      } as ICustomSelectItem<string>;
    },
  );
  // Selected value
  const [selectedAttribute, setSelectedAttribute] = useState<Uuid | undefined>(undefined);

  // Staff Data
  // get all home users that are non-suspended
  const { data, isLoading: isStaffLoading } = useRosterQueryAll([false], ERosterUserOptions.home);
  const [staffItems, _total]: [IStaffWithDetailsDto[], number] = useMemo(() => {
    const { data: items = [], total: totalStaff } = data || {};
    return [items.filter((item) => item.userType === User.ERole.staff), totalStaff] as [
      IStaffWithDetailsDto[],
      number,
    ];
  }, [data]);
  // All options for custom select
  const staffItemsCustomSelect: ICustomSelectItem<Uuid>[] = useMap(staffItems, (staff) => {
    return {
      ...staff,
      value: staff.id,
      label: `${staff.lastName} ${staff.firstName}, ${staff.staffTypeName}`,
      item: staff.id,
    } as ICustomSelectItem<Uuid>;
  });
  // Selected values
  // Default selected based on data
  const initialUsers = useMemo(() => {
    return staffItems
      .filter((staff) => staff.attributes?.includes(selectedAttribute || ""))
      .map((staff) => staff.id);
  }, [staffItems, selectedAttribute]);
  const [selectedUsers, setFilteredUsers] = useState<Uuid[]>(initialUsers);

  // Set current unit as default
  useEffect(() => {
    setSelectedUnit(currentUnitId);
  }, [currentUnitId]);

  // Update filteredUsers when selectedUnit changes
  useEffect(() => {
    setFilteredUsers(initialUsers);
  }, [initialUsers]);

  // Update selectedAttribute when selectedUnit changes
  useEffect(() => {
    setSelectedAttribute(undefined);
  }, [selectedUnit]);

  // Content
  const modalContent = (
    <Box mt={3}>
      <CustomSelect<Uuid>
        items={unitsCustomSelect}
        label="Select unit"
        value={selectedUnit}
        onChange={(event) => setSelectedUnit(event.target.value)}
        sx={{ mb: 3 }}
      />
      <CustomSelect<string>
        items={attributesCustomSelect}
        label="Select positions"
        value={selectedAttribute}
        onChange={(event) => setSelectedAttribute(event.target.value)}
        sx={{ mb: 3 }}
      />
      <Typography sx={{ mb: 2 }}>
        Select or deselect staff to be eligible for the positions to unit above:
      </Typography>
      <Box maxWidth={"500px"}>
        <CustomSelect<Uuid>
          multiple
          checked
          customDisplayOption={`${selectedUsers.length} Users Selected`}
          items={staffItemsCustomSelect}
          label="Staff eligible to for positions to unit"
          value={selectedUsers}
          onChange={(event) => {
            const values = Array.isArray(event.target.value)
              ? event.target.value
              : [event.target.value];
            setFilteredUsers(values as Uuid[]);
          }}
          sx={{ mb: 3 }}
          disabled={!selectedAttribute || !selectedUnit}
          displayEmpty
        />
      </Box>
      <CustomButton
        label={"Select All"}
        onClick={() => setFilteredUsers(staffItems.map((staff) => staff.id))}
        type="submit"
      />
    </Box>
  );

  // Mutation to alter data
  const { mutate: updateStaffDetails, isPending: isSubmitting } = useBulkSaveStaffDetails({
    onSuccess: () => {
      showSuccess("Position eligibility updated successfully.");
    },
    onError: () => {
      showError("Failed to update position eligibility.");
    },
  });

  const saveAttributeEligibility = () => {
    if (selectedUnit) {
      const staffDetailsToChange: TBulkSaveStaffDetails = [];

      // Add attribute to staff newly eligible
      selectedUsers.forEach((userId) => {
        if (!initialUsers.includes(userId)) {
          staffDetailsToChange.push({
            userId,
            attributeKeys: [
              ...(staffItems.find((staff) => staff.id === userId)?.attributes || []),
              selectedAttribute,
            ] as Attribute.DTO["key"][],
          });
        }
      });

      // Remove attribute from staff no longer eligible
      initialUsers.forEach((userId) => {
        if (!selectedUsers.includes(userId)) {
          const currentAttributes =
            staffItems.find((staff) => staff.id === userId)?.attributes || [];
          staffDetailsToChange.push({
            userId,
            attributeKeys: currentAttributes.filter(
              (attrKey) => attrKey !== selectedAttribute,
            ) as Attribute.DTO["key"][],
          });
        }
      });

      updateStaffDetails({
        staffDetails: staffDetailsToChange,
      });
    }
  };

  if (!currentUnitId || isStaffLoading) return null;

  return (
    <CustomModal
      isOpen={isEditAttributeEligibilityOpen}
      primaryBtnText="Save"
      modalContent={modalContent}
      modalHeaderText={"Edit Position Eligibility"}
      onSubmit={saveAttributeEligibility}
      onSecondaryBtnClick={handleSecondaryBtnClick}
      // disabled until you edit something aka change the selected users
      // (can't just check length because could be same length but different users)
      primaryDisabled={
        !selectedUnit ||
        JSON.stringify(orderBy(selectedUsers)) ===
          JSON.stringify(orderBy(initialUsers) || isSubmitting)
      }
    />
  );
};
