import { isEqual } from "lodash";

import { useAppSelector } from "@/common/hooks";

import BulkUserUploadModal from "../BulkUserUploadModal";
import EditAttributeEligibilityModal from "../EditAttributeEligibilityModal";
import EditFloatEligibilityModal from "../EditFloatEligibilityModal";
import RestoreUserModal from "../RestoreUserModal/RestoreUserModal";
import NoteModal from "../StaffRoster/NoteModal";
import StatusChangeModal from "../StatusChangeModal";
import SuspendUserModal from "../SuspendUserModal";
import UpdateStatusDateModal from "../UpdateStatusDateModal";
import UpdateSuspensionDateModal from "../UpdateSuspensionDateModal";

import StaffUserModal from "./StaffUserModal";
import { UpdateRuleSetModal } from "./UpdateRuleSetModal/UpdateRuleSetModal";
import { UpdateRuleSetsModal } from "./UpdateRuleSetsModal/UpdateRuleSetsModal";
import { UpdateRuleSetUsersModal } from "./UpdateRuleSetUsersModal/UpdateRuleSetUsersModal";

export const RosterModals = () => {
  const {
    isUpdateRuleSetsModalOpen,
    isUpdateRuleSetModalOpen,
    isUpdateRuleSetUsersModalOpen,
    isEditUserModalOpen,
    isAddUserModalOpen,
  } = useAppSelector(
    (state) => ({
      isEditUserModalOpen: state.roster.editUserModal.isOpen,
      isAddUserModalOpen: state.roster.addUserModal.isOpen,
      isUpdateRuleSetsModalOpen: state.roster.updateRuleSetsModal.isOpen,
      isUpdateRuleSetModalOpen: state.roster.updateRuleSetModal.isOpen,
      isUpdateRuleSetUsersModalOpen: state.roster.updateRuleSetUsersModal.isOpen,
    }),
    isEqual,
  );

  return (
    <>
      {(isEditUserModalOpen || isAddUserModalOpen) && <StaffUserModal />}
      <NoteModal />
      <StatusChangeModal />
      <BulkUserUploadModal />
      <SuspendUserModal />
      <RestoreUserModal />
      <EditFloatEligibilityModal />
      <EditAttributeEligibilityModal />
      <UpdateStatusDateModal />
      <UpdateSuspensionDateModal />
      {isUpdateRuleSetsModalOpen && <UpdateRuleSetsModal />}
      {isUpdateRuleSetModalOpen && <UpdateRuleSetModal />}
      {isUpdateRuleSetUsersModalOpen && <UpdateRuleSetUsersModal />}
    </>
  );
};
